import { Network } from '@haechi-labs/face-types';
import {
  Client,
  PrivateKey,
  TokenAssociateTransaction,
  Transaction,
  TransferTransaction,
} from '@hashgraph/sdk';
import { HashConnect, HashConnectTypes } from 'hashconnect';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { RICH_WALLET_PRIVATE_KEY } from '../../config/faucetAccount';
import { faceAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Message from '../common/Message';

const title = 'Hedera';
const hashConnect = new HashConnect();
function Hedera() {
  const face = useRecoilValue(faceAtom);
  const [topic, setTopic] = useState('');

  async function connectHashConnect() {
    const metadata: HashConnectTypes.AppMetadata = {
      name: 'Facewallet Sample Dapp',
      description: 'for facewallet test',
      icon: 'https://my.facewallet.xyz',
    };
    const initData = await hashConnect.init(metadata, 'testnet', true);
    await face?.hedera.getHashConnect().init();
    const accountId = (await face?.hedera.getAccountId()) ?? '';

    const savedData = initData.savedPairings.find((p) => p.accountIds.includes(accountId));
    if (savedData) {
      setTopic(savedData.topic);
      console.log('success load hash connect data');
      return;
    }

    const pairingString = hashConnect.generatePairingString(initData.topic, 'testnet', false);
    const pairData = await face?.hedera.getHashConnect().pair(pairingString);
    console.log(pairData);
    if (pairData === undefined) {
      console.error('Fail Pair HashConnect');
      return;
    }
    setTopic(pairData.topic);
    hashConnect.saveDataInLocalstorage();
    console.log('success hash connect paring');
  }

  async function gaslessAssociate() {
    const operatorKey = PrivateKey.fromStringECDSA(RICH_WALLET_PRIVATE_KEY);
    const operatorId = '0.0.2662993';

    const accounts = await face?.auth.getCurrentUser();
    const client = Client.forTestnet()
      .setMirrorNetwork(['testnet.mirrornode.hedera.com:443'])
      .setOperator(operatorId, operatorKey);

    if (!accounts) return;

    const account = (await face?.hedera.getAccountId()) ?? '';

    const tokenId = '0.0.5750380';
    const transactionBytes = await new TokenAssociateTransaction()
      .setAccountId(account)
      .setTokenIds([tokenId])
      .freezeWith(client)
      .toBytes();

    const signed = await hashConnect.sendTransaction(topic, {
      topic,
      byteArray: transactionBytes,
      metadata: {
        accountToSign: account,
        returnTransaction: true,
      },
    });

    if (!signed.success) {
      console.log(`Hedera Signed Fail ${signed.error}`);
      return;
    }

    const signedTransaction = Transaction.fromBytes(signed.signedTransaction as Uint8Array);
    console.log({ signedTransaction });
    const result = await signedTransaction.execute(client);

    console.log('accosiate result', await result.getReceipt(client));
  }

  async function gasleesTransfer() {
    const operatorKey = PrivateKey.fromStringECDSA(RICH_WALLET_PRIVATE_KEY);
    const operatorId = '0.0.2662993';

    const client = Client.forTestnet()
      .setMirrorNetwork(['testnet.mirrornode.hedera.com:443'])
      .setOperator(operatorId, operatorKey);

    const account = (await face?.hedera.getAccountId()) ?? '';

    const transaction = await new TransferTransaction()
      .addHbarTransfer(account, -0.1)
      .addHbarTransfer(operatorId, 0.1)
      .freezeWith(client);

    const signed = await hashConnect.sendTransaction(topic, {
      topic,
      byteArray: transaction.toBytes(),
      metadata: {
        accountToSign: account,
        returnTransaction: true,
      },
    });
    if (!signed.success) {
      console.log(`Hedera Signed Fail ${signed.error}`);
      return;
    }

    const signedTransaction = Transaction.fromBytes(signed.signedTransaction as Uint8Array);
    const result = await signedTransaction.execute(client);

    console.log('transfer result', await result.getReceipt(client));
  }

  async function disconnect() {
    await face?.hedera.getHashConnect().disconnect(topic);
    await hashConnect.clearConnectionsAndData();
    setTopic('');
    console.log('success disconnect');
  }

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }

  if (face.getNetwork() != Network.HEDERA && face.getNetwork() != Network.HEDERA_TESTNET) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the Hedera network.</Message>
      </Box>
    );
  }

  return (
    <Box title={title}>
      <Message type={topic.length === 0 ? 'danger' : 'info'}>
        {topic.length === 0 ? 'Hash Connect is Not Initialize 🛑' : `Pairing Hash Connect  🚀`}
        {topic.length > 0 && <div>{topic}</div>}
      </Message>
      <Button
        onClick={async () => {
          await face?.hedera.hederaAssociate('0.0.5750380');
        }}>
        useGas Associate Hedera Face NFT
      </Button>
      <Button onClick={connectHashConnect}>Pair HashConnect</Button>
      <Button onClick={gaslessAssociate}>Gasless Associate Hedera Face NFT</Button>
      <Button onClick={gasleesTransfer}>Gasless Transfer 0.1 HBAR to Rich Wallet</Button>
      <Button onClick={disconnect}>disconnect</Button>
    </Box>
  );
}

export default Hedera;
