import * as solanaWeb3 from '@solana/web3.js';
import { InMemorySigner } from '@taquito/signer';
import { Signer } from '@taquito/taquito';
import { prefix } from '@taquito/utils';
import bs58check from 'bs58check';
import { ethers } from 'ethers';
import * as nearAPI from 'near-api-js';
import { sign } from 'tweetnacl';

export const RICH_WALLET_PRIVATE_KEY =
  '0x4a8daa34441416a61ee11e3d0676b3b2c9cb07c96eb61126d6e5c88a422db27f';

// const RICH_WALLET_ADDRESS = '0xb64DEf0FC5B70E256130Eb91f36B628d38b223C7';
// solana address: Cd5TcY9sp9N9c1rsSFAy6UhUna5MzJDvXmXzBi5iA8n7
// near address: acae81c701419a05b5d0f74996990ff799a46d65242f0b5f39fb9cb8b7701dec

export function getSolanaRichWallet() {
  const uint8array = ethers.utils.arrayify(RICH_WALLET_PRIVATE_KEY);
  const keypair = solanaWeb3.Keypair.fromSeed(uint8array);
  return keypair;
}

export function getNearRichWallet() {
  const privateKey = ethers.utils.arrayify(RICH_WALLET_PRIVATE_KEY);
  const rawKeyPair = sign.keyPair.fromSeed(privateKey);
  const keyPair = nearAPI.utils.KeyPair.fromString(
    nearAPI.utils.serialize.base_encode(rawKeyPair.secretKey)
  );
  return keyPair;
}

export async function getTezosWallet(): Promise<Signer> {
  const privateKeyBuffer = Buffer.from(RICH_WALLET_PRIVATE_KEY.substring(2), 'hex');
  const secretKey = bs58check.encode(Buffer.concat([prefix.edsk2, privateKeyBuffer]));
  return await InMemorySigner.fromSecretKey(secretKey);
}
