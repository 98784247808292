import { Env } from '@haechi-labs/face-types';
import originAxios from 'axios';

export const getOperationApiBaseURL = (env: Env) => {
  switch (env) {
    case Env.Dev:
      return 'https://api.dev.facewallet.xyz';
    case Env.Local:
      return 'http://localhost:8881';
    case Env.StageTest:
    default:
      return 'https://api.stage-test.facewallet.xyz';
  }
};

const axios = originAxios.create({
  baseURL: getOperationApiBaseURL(Env.Dev),
});

export default axios;
