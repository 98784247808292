import { useRecoilValue } from 'recoil';

import { faceAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Message from '../common/Message';

const title = 'ETC';
function Etc() {
  const face = useRecoilValue(faceAtom);

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }

  return (
    <Box title={title}>
      <Button
        onClick={() => {
          console.log('The Facewallet will close after 5 seconds.');
          setTimeout(() => {
            face!.wallet.close();
          }, 5000);
        }}>
        Close wallet after 5 secs.
      </Button>
    </Box>
  );
}

export default Etc;
