import { Network, TezosContract } from '@haechi-labs/face-sdk';
import { TezosTransferFa2Params } from '@haechi-labs/face-types';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { getExplorerUrl } from '../../libs/utils';
import { accountAtom, faceAtom, networkAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Field from '../common/Field';
import Message from '../common/Message';

export const fa2MftContractAddressMap = {
  [Network.TEZOS]: 'KT18gYdA2yzE6qZU12q5ouAbSxPc4JLMA6UF', // NFT
  [Network.GHOSTNET]: 'KT1GphWDUW5rEMsjL74JYi6q9L5yoMWXWWr8', // NFT
};

const title = 'FA2 MFT Transaction';

function TezosFa2Mft() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const network = useRecoilValue(networkAtom);
  const [txHash, setTxHash] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [tokenAmount, setTokenAmount] = useState(10);
  const [contractAddress, setContractAddress] = useState('');
  const [receiverAddress, setReceiverAddress] = useState('');

  useEffect(() => {
    // Set receiver to user account
    if (account.address) {
      setReceiverAddress(account.address);
    }
  }, [account.address]);

  useEffect(() => {
    if (network != Network.TEZOS && network != Network.GHOSTNET) {
      setContractAddress('NOT SUPPORTED');
      return;
    }
    setContractAddress(fa2MftContractAddressMap[network]);
  }, [network]);

  async function sendTransaction() {
    try {
      if (!tokenId) {
        alert('Please enter token ID');
        return;
      }
      if (!contractAddress) {
        alert('Please enter contract address');
        return;
      }
      if (!receiverAddress) {
        alert('Please enter receiver address');
        return;
      }
      if (!tokenAmount) {
        alert('Please enter token amount');
        return;
      }

      const contract: TezosContract = await face!.tezos.contract.at(contractAddress);
      const address = (await face!.tezos.wallet.getAddresses())[0];
      const transferParams: TezosTransferFa2Params[] = [
        {
          from_: address,
          txs: [
            {
              to_: receiverAddress,
              token_id: Number(tokenId),
              amount: tokenAmount,
            },
          ],
        },
      ];
      const hash = await contract.methods.transfer(transferParams);

      setTxHash(hash);

      console.group('[Transaction Information]');
      console.log('Transaction response:', hash);
      console.log('Explorer Link:', `${getExplorerUrl(network!, hash)}`);
      console.groupEnd();
    } catch (error) {
      console.error(error);
    }
  }

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }
  if (!account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }
  if (network != Network.TEZOS && network != Network.GHOSTNET) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the Tezos network.</Message>
      </Box>
    );
  }

  return (
    <Box title={title} id="transferFa2Mft">
      <Field label="Token ID">
        <input
          className="input"
          value={tokenId}
          onChange={(e) => setTokenId(e.target.value)}
          type="number"
        />
      </Field>
      <Field label="Token amount">
        <input
          className="input"
          value={tokenAmount}
          onChange={(e) => setTokenAmount(Number(e.target.value))}
          type="number"
        />
      </Field>
      <Field label="Contract Address">
        <input
          className="input"
          value={contractAddress}
          onChange={(e) => setContractAddress(e.target.value)}
        />
      </Field>
      <Field label="Receiver Address">
        <input
          className="input"
          value={receiverAddress}
          onChange={(e) => setReceiverAddress(e.target.value)}
        />
      </Field>
      <Button onClick={sendTransaction}>Transfer FA2 token</Button>
      {txHash && (
        <>
          <Message type="info">Hash: {txHash}</Message>
          <Message type="info">
            <a
              href={`${getExplorerUrl(network!, txHash)}`}
              rel="noopener noreferrer"
              target="_blank">
              Explorer Link
            </a>
          </Message>
        </>
      )}
    </Box>
  );
}

export default TezosFa2Mft;
