import { assertArgument, Blockchain, HomeOptions } from '@haechi-labs/face-types';

import { Internal } from './internal';

/**
 * The interface to control view of facewallet.
 */
export class Wallet {
  private readonly internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  close() {
    this.internal.iframe.hideOverlay();
  }

  async home(options?: HomeOptions) {
    assertArgument(options?.networks, options?.networks?.length, 'options.networks', false);

    return this.internal.openHome(options);
  }

  /**
   * Open onRamp(MoonPay) modal for the passed blockchains
   *
   * @method
   * @returns {Promise<void>}
   */
  async onRamp(blockchains: Blockchain[]) {
    assertArgument(
      blockchains,
      blockchains.length > 0 && blockchains.every((b) => Blockchain[b]),
      'blockchains'
    );

    return this.internal.openBuy({ blockchains });
  }

  /**
   * Open onRamp(MoonPay) modal for the token
   * Contract address for platform coin like ETH, MATIC is '0x0'
   *
   * @method
   * @returns {Promise<void>}
   */
  async onRampToken(blockchain: Blockchain, contractAddress: string, coinAmount?: number) {
    assertArgument(blockchain, Blockchain[blockchain], 'blockchain');
    assertArgument(contractAddress, contractAddress, 'contractAddress');
    assertArgument(coinAmount, coinAmount && coinAmount > 0, 'coinAmount', false);

    return this.internal.openBuy({ blockchains: [blockchain], contractAddress, coinAmount });
  }
}
