import { Internal } from '../internal';

export class HashConnect {
  internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  async getPairString() {
    return await this.internal.hederaGetHashConnectPairString();
  }

  async init() {
    return await this.internal.initHashConnect();
  }

  async pair(state: string): Promise<{
    accountId: string;
    topic: string;
  }> {
    return await this.internal.pairHashConnect(state);
  }

  async disconnect(topic: string) {
    return await this.internal.disconnectHashConnect(topic);
  }
}
