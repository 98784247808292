import { Network } from '@haechi-labs/face-types';

import CheckboxList from './CheckboxList';

interface SelectNetworkProps {
  networks: Network[];
  setNetworks: React.Dispatch<React.SetStateAction<Network[]>> | ((value: Network[]) => void);
  networkOptions?: Network[];
}

function SelectNetwork({ networks, setNetworks, networkOptions }: SelectNetworkProps) {
  const options =
    networkOptions?.map((option) => ({ key: option })) ??
    Object.values(Network).map((network) => ({
      key: network,
    }));

  return <CheckboxList items={options} state={networks} setState={setNetworks as any} />;
}

export default SelectNetwork;
