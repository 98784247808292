import { ethers, providers } from 'ethers';
import { isHexString } from 'ethers/lib/utils';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { accountAtom, faceAtom, walletAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Field from '../common/Field';
import Message from '../common/Message';

const title = 'Default Sign Message';

function SignMessage() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const wallet = useRecoilValue(walletAtom);
  const [message, setMessage] = useState('');
  const [signedMessage, setSignedMessage] = useState('');
  const [recoveredAddress, setRecoveredAddress] = useState('');
  const [verificationFail, setVerificationFail] = useState(false);

  async function signMessage() {
    try {
      const provider = new providers.Web3Provider(
        wallet ? await wallet.connector.getProvider() : face!.getEthLikeProvider(),
        'any'
      );
      const signer = await provider.getSigner();

      const signature = isHexString(message)
        ? await signer.signMessage(Buffer.from(message.slice(2), 'hex'))
        : await signer.signMessage(message);

      let recoverAddress = '';

      if (isHexString(message)) {
        recoverAddress = ethers.utils.verifyMessage(
          Buffer.from(message.slice(2), 'hex'),
          signature
        );
      } else {
        recoverAddress = ethers.utils.verifyMessage(message, signature);
      }

      console.group('[Sign Information]');
      const address = await signer.getAddress();
      console.log('address: ' + address);
      console.log('Signed message:', signature);
      console.log('Verification result:', recoverAddress);
      console.groupEnd();
      setSignedMessage(signature);
      setRecoveredAddress(recoverAddress);
      if (recoverAddress === address) {
        setVerificationFail(false);
      } else {
        setVerificationFail(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function legacySignMessage() {
    try {
      const provider = new providers.Web3Provider(
        wallet ? await wallet.connector.getProvider() : face!.getEthLikeProvider(),
        'any'
      );
      const signer = await provider.getSigner();

      const signature = isHexString(message)
        ? await signer._legacySignMessage(Buffer.from(message.slice(2), 'hex'))
        : await signer._legacySignMessage(message);

      let recoverAddress = '';

      if (isHexString(message)) {
        recoverAddress = ethers.utils.verifyMessage(
          Buffer.from(message.slice(2), 'hex'),
          signature
        );
      } else {
        recoverAddress = ethers.utils.verifyMessage(message, signature);
      }

      console.group('[Sign Information]');
      const address = await signer.getAddress();
      console.log('address: ' + address);
      console.log('Signed message:', signature);
      console.log('Verification result:', recoverAddress);
      console.groupEnd();
      setSignedMessage(signature);
      setRecoveredAddress(recoverAddress);
      if (recoverAddress === address) {
        setVerificationFail(false);
      } else {
        setVerificationFail(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }
  if (!account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  return (
    <Box title={title}>
      <Field label="Message">
        <textarea
          className="textarea"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Field>
      <Button onClick={() => signMessage()}>Sign Message(personal_sign)</Button>
      <Button onClick={() => legacySignMessage()}>Sign Message(eth_sign)</Button>

      {!verificationFail && signedMessage && (
        <Message type="info" className="has-text-left">
          <h4 className="has-text-weight-bold">Signed message</h4>
          <div>{signedMessage}</div>
          <br />
          <h4 className="has-text-weight-bold">Verification result</h4>
          <div>recovered address: {recoveredAddress}</div>
        </Message>
      )}
      {verificationFail && (
        <Message type="danger" className="has-text-left">
          <h4 className="has-text-weight-bold">Signed message</h4>
          <div>{signedMessage}</div>
          <br />
          <h4 className="has-text-weight-bold">Verification result</h4>
          <div>recovered address: {recoveredAddress}</div>
          <div>address: {account.address}</div>
        </Message>
      )}
    </Box>
  );
}

export default SignMessage;
