import { Network } from '@haechi-labs/face-types';
import { isMainnet, mainnetList, networkToBlockchain, testnetList } from '@haechi-labs/shared';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { accountAtom, faceAtom, networkAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Field from '../common/Field';
import Message from '../common/Message';
import SelectNetwork from '../common/SelectNetwork';

const title = 'Buy';

function Buy() {
  const face = useRecoilValue(faceAtom);
  const network = useRecoilValue(networkAtom);
  const account = useRecoilValue(accountAtom);
  const [contractAddress, setContractAddress] = useState<string>('');
  const [coinAmount, setCoinAmount] = useState<number>(0);
  const [networks, setNetworks] = useState<Network[]>([]);

  if (!face || !network) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }
  if (!account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  const openBuy = () => {
    try {
      if (contractAddress) {
        if (networks.length !== 1) {
          alert(
            'Please select only one blockchain if you want to buy a specific coin (with contract address).'
          );
          return;
        }
        face.wallet.onRampToken(
          networkToBlockchain(networks[0]),
          contractAddress,
          coinAmount || undefined
        );
      } else {
        face.wallet.onRamp(networks.map((network) => networkToBlockchain(network)));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box title={title}>
      <SelectNetwork
        networks={networks}
        setNetworks={setNetworks}
        // 문페이는 샌드박스/프로덕션을 구분해줘야해서 현재 환경에 따라서 리스트를 다르게 보여줌
        networkOptions={isMainnet(network) ? mainnetList : testnetList}
      />
      <Field label="Contract Address">
        <input
          name="contract-address"
          className="input"
          type="text"
          onChange={(e) => setContractAddress(e.target.value)}
          value={contractAddress}
        />
      </Field>
      <Field label="Coin amount">
        <input
          name="coin-amount"
          className="input"
          type="number"
          onChange={(e) => setCoinAmount(Number(e.target.value))}
          value={coinAmount}
        />
      </Field>
      <Button onClick={openBuy}>Open buy page</Button>
      {isMainnet(network) && (
        <div style={{ textAlign: 'left' }}>
          *** Mainnet OnRamp can only be run in the Mainnet environment.
        </div>
      )}
    </Box>
  );
}

export default Buy;
