import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { contractAddressMap, sendContractCall } from '../../libs/contractCall';
import { getExplorerUrl } from '../../libs/utils';
import { accountAtom, faceAtom, networkAtom, walletAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Field from '../common/Field';
import Message from '../common/Message';

const title = 'Contract Call Transaction';
function TransactionContractCall() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const network = useRecoilValue(networkAtom);
  const wallet = useRecoilValue(walletAtom);
  const [txHash, setTxHash] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [amount, setAmount] = useState('0');
  const [txData, setTxData] = useState('');

  useEffect(() => {
    // Set default contract address
    if (network) {
      setContractAddress(contractAddressMap[network]);
    }
  }, [network]);

  async function sendTransaction() {
    try {
      if (!contractAddress) {
        alert('Please enter contract address');
        return;
      }

      const sentTransaction = await sendContractCall({
        face: face!,
        contractAddress,
        amount,
        txData,
        network: network!,
        wallet,
      });

      setTxHash(sentTransaction.hash);

      console.group('[Transaction Information]');
      console.log('Transaction response:', sentTransaction.internal);
      console.log('Explorer Link:', `${getExplorerUrl(network!, sentTransaction.hash)}`);

      const receipt = await sentTransaction.wait();
      console.log('Transaction receipt', receipt);
      console.groupEnd();
    } catch (error) {
      console.error(error);
    }
  }

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }
  if (!account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  return (
    <Box title={title}>
      <Field label="Contract Address">
        <input
          className="input"
          value={contractAddress}
          onChange={(e) => setContractAddress(e.target.value)}
        />
      </Field>
      <Field label="Amount">
        <input
          className="input"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
        />
      </Field>
      <Field label="Tx Data">
        <input className="input" value={txData} onChange={(e) => setTxData(e.target.value)} />
      </Field>
      <Button onClick={sendTransaction}>Call contract</Button>
      {txHash && (
        <>
          <Message type="info">Hash: {txHash}</Message>
          <Message type="info">
            <a
              href={`${getExplorerUrl(network!, txHash)}`}
              rel="noopener noreferrer"
              target="_blank">
              Explorer Link
            </a>
          </Message>
        </>
      )}
      <Message type="dark" className="has-text-left">
        <h4 className="has-text-weight-bold">Sample data for sample contract</h4>
        <div>
          For success: <span className="tag is-success is-light">0x0b93381b</span>
        </div>
        <div>
          For failure: <span className="tag is-danger is-light">0xa9cc4718</span>
        </div>
      </Message>
    </Box>
  );
}

export default TransactionContractCall;
