import { ConvertModule } from '@haechi-labs/face-convert';
import { Face, Network } from '@haechi-labs/face-sdk';
import { SwapModule } from '@haechi-labs/face-swap';
import { isEthlikeNetwork } from '@haechi-labs/shared';
import { ERC20_APPROVE_ABI } from '@haechi-labs/shared/constants/abi';
import { BigNumber, ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { accountAtom, faceAtom, loginStatusAtom, networkAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Field from '../common/Field';
import Message from '../common/Message';
import Select from '../common/Select';
import SelectNetwork from '../common/SelectNetwork';

const title = 'Wallet Home';

type Contract = {
  name: string;
  address: string;
};

function WalletHome() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const isLoggedIn = useRecoilValue(loginStatusAtom);
  const network = useRecoilValue(networkAtom)!;
  const [networks, setNetworks] = useState<Network[]>([]);
  const [erc20Contract, setErc20Contract] = useState<Contract | null>(null);
  const [spenderContract, setSpenderContract] = useState<Contract | null>(null);
  const [allowance, setAllowance] = useState<string | null>(null);
  const [allowanceRefresh, setAllowanceRefresh] = useState<number>(0);

  useEffect(() => {
    if (network == null) {
      return;
    }
    setErc20Contract(getERC20ContractsForApproval(network)[0]);
    setSpenderContract(getSpenderContracts(network)[0]);
  }, [network]);

  useEffect(() => {
    setAllowance(null);

    if (
      face == null ||
      erc20Contract == null ||
      account?.address == null ||
      spenderContract == null
    ) {
      return;
    }

    try {
      if (isEthlikeNetwork(face.getNetwork())) {
        getAllowance({ face, erc20Contract, walletAddress: account.address, spenderContract })
          .then((allowance: BigNumber | number) => {
            console.log('allowance', allowance);
            if (typeof allowance === 'number') {
              allowance = ethers.BigNumber.from(allowance);
            }
            setAllowance(allowance.toString());
          })
          .catch((e: Error) => {
            console.error(`failed to get allowance`);
            console.error({
              'account.address': account.address,
              'erc20Contract.address': erc20Contract.address,
              'spenderContract.address': spenderContract.address,
            });
            console.error(e);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }, [face, erc20Contract, account, allowanceRefresh, spenderContract]);

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }

  if (!isLoggedIn) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  const handleApprove = (amount: number) => {
    if (
      face == null ||
      erc20Contract == null ||
      account?.address == null ||
      spenderContract == null
    ) {
      return;
    }

    approve({
      face,
      walletAddress: account.address,
      erc20Contract: erc20Contract,
      spenderContract: spenderContract,
      amount,
    })
      .catch((error) => console.error(error))
      .finally(() => {
        setAllowanceRefresh(allowanceRefresh + 1);
      });
  };

  return (
    <Box title={title}>
      <SelectNetwork networks={networks} setNetworks={setNetworks} />
      <Button onClick={() => face.wallet.home()}>Open wallet home for all networks</Button>
      <Button
        onClick={() => {
          if (networks.length === 0) {
            alert('Please select networks.');
            return;
          }
          console.log('open home with networks', networks);
          face.wallet.home({ networks });
        }}>
        Open wallet home for selected networks
      </Button>
      <Field label="ERC20 Contract">
        <Select
          items={getERC20ContractsForApproval(network).map((contract) => {
            return {
              value: contract.address,
              name: `${contract.name} - ${contract.address.slice(0, 6)}...`,
            };
          })}
          onSelect={(value) => {
            const selectedApproval = getERC20ContractsForApproval(network).filter(
              (contract) => contract.address === value
            )[0];
            setErc20Contract(selectedApproval);
          }}
          value={erc20Contract?.address}
        />
      </Field>

      <Field label="Spender Contract(Swap or Convert)">
        <Select
          items={getSpenderContracts(network).map((contract) => {
            return {
              value: contract.address,
              name: `${contract.name} - ${contract.address.slice(0, 6)}...`,
            };
          })}
          onSelect={(value) => {
            const selectedSpender = getSpenderContracts(network).filter(
              (contract) => contract.address === value
            )[0];
            setSpenderContract(selectedSpender);
          }}
          value={spenderContract?.address}
        />
      </Field>
      {
        <Field label="allowance">
          {allowance == null ? (
            'CALCULATING...'
          ) : (
            <span data-test-id={'allowance'}>{allowance}</span>
          )}
        </Field>
      }
      {erc20Contract && <Button onClick={() => handleApprove(0)}>CancelApprove</Button>}
      {erc20Contract && <Button onClick={() => handleApprove(9999)}>Approve</Button>}
      {!erc20Contract && <Message type="info">No Convertable Coin.</Message>}
    </Box>
  );
}

function getAllowance({
  face,
  erc20Contract,
  walletAddress,
  spenderContract,
}: {
  face: Face;
  erc20Contract: Contract;
  walletAddress: string;
  spenderContract: Contract;
}) {
  const contract = new ethers.Contract(
    erc20Contract.address,
    ERC20_APPROVE_ABI,
    new ethers.providers.Web3Provider(face.getEthLikeProvider())
  );
  return contract.allowance(walletAddress, spenderContract.address);
}

function getERC20ContractsForApproval(network: Network): Contract[] {
  try {
    const convertModule = new ConvertModule();
    const swapModule = new SwapModule();
    const convertibleCoins = convertModule
      .getConvertibleCoinList()
      .map((coin) => ({ name: 'unknown', ...coin }))
      .filter((coin) => coin.network === network);
    const swappableCoins = swapModule
      .getSwappableCoinList()
      .map((coin) => ({ name: 'unknown', ...coin }))
      .filter((coin) => coin.network === network);
    return uniqueContract(
      [...convertibleCoins, ...swappableCoins].filter((coin) => coin.address !== '0x0')
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

function uniqueContract(contracts: Contract[]): Contract[] {
  const uniqueContracts: Contract[] = [];
  contracts.forEach((contract) => {
    if (!uniqueContracts.some((uniqueContract) => uniqueContract.address === contract.address)) {
      uniqueContracts.push(contract);
    }
  });
  return uniqueContracts;
}

function getSpenderContracts(network: Network): Contract[] {
  const convertModule = new ConvertModule();
  const swapModule = new SwapModule();

  return [
    ...convertModule
      .getSpenderContractAddress(network)
      .filter(
        (address, index) =>
          convertModule.getSpenderContractAddress(network).indexOf(address) === index
      ) // 중복 제거
      .map((address) => ({
        address,
        name: 'Convert',
      })),
    ...swapModule
      .getSpenderContractAddress(network)
      .filter(
        (address, index) => swapModule.getSpenderContractAddress(network).indexOf(address) === index
      ) // 중복 제거
      .map((address) => ({
        address,
        name: 'Swap',
      })),
  ];
}

async function approve(args: {
  face: Face;
  walletAddress: string;
  erc20Contract: Contract;
  spenderContract: Contract;
  amount: number;
}) {
  try {
    const contract = new ethers.Contract(
      args.erc20Contract.address,
      ERC20_APPROVE_ABI,
      new ethers.providers.Web3Provider(args.face.getEthLikeProvider()).getSigner()
    );

    const transactionResponse = await contract.approve(
      // get address of convert contract
      args.spenderContract.address,
      BigNumber.from(args.amount)
    );

    console.log('transactionResponse', transactionResponse);
    const receipt = await transactionResponse.wait();
    console.log('receipt', receipt);
  } catch (error) {
    console.error(error);
  }
}

export default WalletHome;
