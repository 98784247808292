import {
  assertArgument,
  Blockchain,
  BoraPortalConnectRequest,
  BoraPortalConnectStatusResponse,
  Env,
  FaceError,
  FaceIdTokenResponse,
  FaceLoginResponse,
  HomeOptions,
  JsonRpcMethod,
  JsonRpcRequestPayload,
  JsonRpcResponsePayload,
  LoginProviderType,
  LoginWithAccessTokenRequest,
  LoginWithIdTokenRequest,
  OnRampOptions,
  ProviderRpcError,
} from '@haechi-labs/face-types';
import { isEthlikeNetwork, isSupportedNetwork } from '@haechi-labs/shared';
import { BigNumber, ethers } from 'ethers';

import eventEmitter from './events';
import { Face, Network, NotificationOptions } from './face';
import { Iframe } from './iframe';
import {
  getBlockchainFromNetwork,
  getEthlikeChainIdFromNetwork,
  getIframeUrl,
  getNetwork,
} from './utils';

const DEFAULT_ETH_GAS_PRICE = BigNumber.from(100000).toHexString();

type InternalParams = {
  face: Face;
  apiKey: string;
  network?: Network;
  env?: Env;
  iframeUrl?: string;
  notificationOptions?: NotificationOptions;
};

export class Internal {
  private readonly face: Face;
  private readonly env: Env;
  private network: Network;
  public readonly iframe: Iframe;
  public readonly iframeUrl?: string;

  constructor({ apiKey, network, env, iframeUrl, face, notificationOptions }: InternalParams) {
    this.network = network || Network.ETHEREUM;
    // TODO: env is not configured yet, so need to change as env configured

    this.env = env ?? Env.ProdMainnet;
    this.iframe = new Iframe(
      apiKey,
      this.network,
      this.env,
      getIframeUrl(this.env, iframeUrl),
      notificationOptions
    );
    this.face = face;
    this.iframeUrl = iframeUrl;

    if (notificationOptions?.type === 'toast') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      import('@haechi-labs/toast');
    }
  }

  async getAddresses(blockchain?: Blockchain): Promise<string[]> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_accounts,
      params: [blockchain],
    });
    const response = await this.iframe.waitForResponse<null | string[]>(requestId);
    if (response) {
      return response;
    }
    return Promise.reject(new Error(`get address failed`));
  }

  // SDK에서 estimateGas를 호출하는 대부분의 경우는 트랜잭션을 보내는 상황에서 ethers가 호출하는 것
  // 이 상황에서는 0으로 리턴하고 iframe 안에서 덮어씌우는 게 유저 경험이 더 좋음
  async estimateGas() {
    return 0;
  }

  async getBalance(address: string, contractAddress?: string): Promise<BigNumber> {
    if (contractAddress) {
      const callData = await this.encodeData(
        ['function balanceOf(address owner) view returns (uint256)'],
        'balanceOf',
        [address]
      );

      const result = await this.sendRpc({
        method: JsonRpcMethod.eth_call,
        params: [
          {
            to: contractAddress,
            data: callData,
          },
          'latest',
        ],
      });

      return BigNumber.from(result);
    }

    return BigNumber.from(
      await this.sendRpc({
        method: JsonRpcMethod.eth_getBalance,
        params: [address, 'latest'],
      })
    );
  }

  async ownerOf(contractAddress: string, tokenId: string): Promise<string> {
    const callData = await this.encodeData(
      ['function ownerOf(uint256 tokenId) view returns (address)'],
      'ownerOf',
      [tokenId]
    );
    const result = await this.sendRpc({
      method: JsonRpcMethod.eth_call,
      params: [
        {
          to: contractAddress,
          data: callData,
        },
        'latest',
      ],
    });

    return ('0x' + (result as string).substring(26)).toLowerCase();
  }

  async logout(): Promise<void> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_logOut,
    });
    await this.iframe.waitForResponse<FaceLoginResponse | null>(requestId);
  }

  async getCurrentUser(): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_currentUser,
    });
    return await this.iframe.waitForResponse<FaceLoginResponse>(requestId);
  }

  async isLoggedIn(): Promise<boolean> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_loggedIn,
    });
    const response = await this.iframe.waitForResponse<boolean>(requestId);
    if (response) {
      return response;
    }
    return false;
  }

  async ready(): Promise<void> {
    return this.iframe.ready();
  }

  async loginWithCredential(providers?: LoginProviderType[]): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_logInSignUp,
      params: providers,
    });
    return await this.iframe.waitForResponse<FaceLoginResponse | null>(requestId);
  }

  async directSocialLogin(provider: LoginProviderType): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_directSocialLogin,
      params: [provider],
    });
    return await this.iframe.waitForResponse<FaceLoginResponse | null>(requestId);
  }

  async boraLogin(
    boraRequest: BoraPortalConnectRequest,
    providers?: LoginProviderType[]
  ): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_logInSignUp,
      params: [boraRequest, providers],
    });
    return await this.iframe.waitForResponse<
      (FaceLoginResponse & { boraPortalConnectStatus: BoraPortalConnectStatusResponse }) | null
    >(requestId);
  }

  async boraDirectSocialLogin(
    boraRequest: BoraPortalConnectRequest,
    provider: LoginProviderType
  ): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_directSocialLogin,
      params: [provider, boraRequest],
    });
    return await this.iframe.waitForResponse<
      (FaceLoginResponse & { boraPortalConnectStatus: BoraPortalConnectStatusResponse }) | null
    >(requestId);
  }

  async boraLoginWithIdToken(
    boraRequest: BoraPortalConnectRequest,
    loginWithIdTokenRequest: LoginWithIdTokenRequest
  ): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_loginWithIdToken,
      params: [loginWithIdTokenRequest, boraRequest],
    });
    return await this.iframe.waitForResponse<
      (FaceLoginResponse & { boraPortalConnectStatus: BoraPortalConnectStatusResponse }) | null
    >(requestId);
  }

  async getIdToken(
    provider: LoginProviderType,
    accessToken: string
  ): Promise<FaceIdTokenResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_getIdToken,
      params: [provider, accessToken],
    });
    return await this.iframe.waitForResponse<FaceIdTokenResponse | null>(requestId);
  }

  async loginWithIdToken(
    loginWithIdTokenRequest: LoginWithIdTokenRequest
  ): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_loginWithIdToken,
      params: [loginWithIdTokenRequest],
    });
    return await this.iframe.waitForResponse<FaceLoginResponse | null>(requestId);
  }

  async loginWithAccessToken(
    loginWithAccessTokenRequest: LoginWithAccessTokenRequest
  ): Promise<FaceLoginResponse | null> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_loginWithAccessToken,
      params: [loginWithAccessTokenRequest],
    });
    return await this.iframe.waitForResponse<FaceLoginResponse | null>(requestId);
  }

  async openWalletConnect(name: string, url: string): Promise<void> {
    this.iframe.throwExceptionUnsupportedBlockchain([
      Blockchain.APTOS,
      Blockchain.NEAR,
      Blockchain.SOLANA,
      Blockchain.PSM,
      Blockchain.TEZOS,
    ]);

    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_openWalletConnect,
      params: [name, url],
    });
    return await this.iframe.waitForResponse<void>(requestId);
  }

  async openHome(options?: HomeOptions): Promise<void> {
    this.iframe.throwExceptionUnsupportedBlockchain([Blockchain.SOLANA, Blockchain.NEAR]);
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_openHome,
      params: [options],
    });
    return this.iframe.waitForResponse(requestId);
  }

  async openBuy(options?: OnRampOptions): Promise<void> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_openBuy,
      params: [options],
    });
    return this.iframe.waitForResponse(requestId);
  }

  async sendRpc<T>(
    rpcPayload: JsonRpcRequestPayload
  ): Promise<JsonRpcResponsePayload<T>['result']> {
    const requestId = await this.iframe.sendChildMessage(rpcPayload);
    return await this.iframe.waitForResponse<JsonRpcResponsePayload<T>['result']>(requestId);
  }

  async decodeData(serializedTx: string, abi: string[]) {
    const ethersInterface = new ethers.utils.Interface(abi);
    const { name, args } = ethersInterface.parseTransaction({ data: serializedTx });

    return { name, args };
  }

  async encodeData(abi: string[], functionFragment: string, args?: any[]) {
    const ethersInterface = new ethers.utils.Interface(abi);
    return ethersInterface.encodeFunctionData(functionFragment, args);
  }

  async switchNetwork(network: Network | number | string) {
    try {
      const _network = getNetwork(network);
      assertArgument(network, isSupportedNetwork(_network, ['in-app']), 'network');
      const blockchain = getBlockchainFromNetwork(_network);
      const request = {
        method: JsonRpcMethod.face_switchNetwork,
        params: [{ network: _network }],
      };
      this.network = _network;
      this.iframe.setNetwork(_network);
      this.iframe.setBlockchain(blockchain);
      const res = await this.sendRpc(request);

      if (isEthlikeNetwork(this.network)) {
        const chainId = getEthlikeChainIdFromNetwork(_network);
        eventEmitter.emit('chainChanged', chainId);
      }

      return res;
    } catch (e) {
      if (isEthlikeNetwork(this.network)) {
        const error: ProviderRpcError = {
          name: (e as FaceError).name,
          message: (e as FaceError).message,
          code: 4901,
        };
        eventEmitter.emit('disconnect', error);
      }
      throw e;
    }
  }

  async boraIsConnected(bappUsn: string): Promise<BoraPortalConnectStatusResponse | null> {
    this.iframe.allowBlockchain([Blockchain.BORA]);

    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.bora_isConnected,
      params: [bappUsn],
    });
    return await this.iframe.waitForResponse<BoraPortalConnectStatusResponse | null>(requestId);
  }

  async boraConnect(
    request: BoraPortalConnectRequest
  ): Promise<BoraPortalConnectStatusResponse | null> {
    this.iframe.allowBlockchain([Blockchain.BORA]);

    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.bora_connect,
      params: [request],
    });
    return await this.iframe.waitForResponse<BoraPortalConnectStatusResponse | null>(requestId);
  }

  async getUserVerificationToken(): Promise<string> {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.face_getUserVerificationToken,
      params: [],
    });
    const response = await this.iframe.waitForResponse<null | string>(requestId);
    if (response) {
      return response;
    }
    return Promise.reject(new Error(`get user verification token failed`));
  }

  getNetwork(): Network {
    return this.network;
  }

  async hederaAssociate(tokenId: string) {
    this.iframe.allowBlockchain([Blockchain.HEDERA]);

    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.hedera_associate,
      params: [tokenId],
    });
    return await this.iframe.waitForResponse<boolean>(requestId);
  }

  async hederaGetHashConnectPairString() {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.hedera_getHashConnectPairString,
      params: [],
    });
    return await this.iframe.waitForResponse<string>(requestId);
  }

  async pairHashConnect(state: string) {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.hedera_pairHashConnect,
      params: [state],
    });
    return await this.iframe.waitForResponse<{
      accountId: string;
      topic: string;
    }>(requestId);
  }

  async getHederaAccountId() {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.hedera_getAccountId,
    });
    return await this.iframe.waitForResponse<string>(requestId);
  }

  async initHashConnect() {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.hedera_initHashConnect,
    });
    return await this.iframe.waitForResponse<void>(requestId);
  }

  async disconnectHashConnect(topic: string) {
    const requestId = await this.iframe.sendChildMessage({
      method: JsonRpcMethod.hedera_disconnectHashConnect,
      params: [topic],
    });
    return await this.iframe.waitForResponse<void>(requestId);
  }
}
