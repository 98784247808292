import { Internal } from '../internal';
import { TezosContract } from './TezosContract';
import { TezosWallet } from './TezosWallet';

export class Tezos {
  private readonly internal: Internal;
  public contract: ProxyContract;
  public wallet: TezosWallet;

  constructor(internal: Internal) {
    this.internal = internal;
    this.wallet = new TezosWallet(internal);
    this.contract = new ProxyContract(internal);
  }
}

class ProxyContract {
  private readonly internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  public async at(contractAddress: string): Promise<TezosContract> {
    return new TezosContract(this.internal, new TezosWallet(this.internal), contractAddress);
  }
}
