import { Network } from '@haechi-labs/face-types';
import { networkToBlockchain } from '@haechi-labs/shared';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import {
  mintErc721,
  mintErc1155,
  mintTezosNFT,
  sendErc20,
  sendFa2Ft,
  sendPlatformCoin,
} from '../../libs/faucet';
import { createLargeDecimalFT, createPlatformCoin, createSmallDecimalFT } from '../../libs/types';
import { accountAtom, faceAtom, networkAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Message from '../common/Message';
import { fa2FtDecimal6ContractAddressMap, fa2FtDecimal18ContractAddressMap } from './TezosFa2Ft';
import { fa2MftContractAddressMap } from './TezosFa2Mft';
import {
  erc20Decimal6ContractAddressMap,
  erc20Decimal18ContractAddressMap,
} from './TransactionErc20';
import { erc721ContractAddressMap } from './TransactionErc721';
import { erc1155ContractAddressMap } from './TransactionErc1155';

const title = 'Faucet';

function Faucet() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const network = useRecoilValue(networkAtom)!;
  const blockchain = network == null ? null : networkToBlockchain(network);

  const [faucetResult, setFaucetResult] = useState<string>();

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }
  if (!account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  return (
    <Box title={title}>
      {faucetResult && (
        <Message
          type={faucetResult?.includes('Failed') ? 'danger' : 'info'}
          className="has-text-left">
          {faucetResult}
        </Message>
      )}
      <Button
        onClick={async () => {
          try {
            const response = await sendPlatformCoin(
              face,
              createPlatformCoin('0.01', blockchain!),
              account.address!,
              network
            );
            const receipt = await response?.wait();

            if (receipt?.status === true) {
              setFaucetResult(`Succeeded to get 0.01 coin.`);
            } else {
              throw receipt;
            }
          } catch (e) {
            const message = 'Failed to get 0.01 coin.';
            setFaucetResult(message);
            console.error(message, e);
          }
        }}
        className="btn btn-outline-success">
        Get platform coin
      </Button>
      <Button
        onClick={async () => {
          try {
            const response = await sendErc20(
              face,
              createLargeDecimalFT('0.01', blockchain!),
              account.address!,
              erc20Decimal18ContractAddressMap[network],
              network
            );

            const receipt = await response?.wait();

            if (receipt?.status) {
              setFaucetResult(`Succeeded to get 0.01 token. (Decimal 18)`);
            } else {
              throw receipt;
            }
          } catch (e) {
            const message = 'Failed to get 0.01 token. (Decimal 18)';
            setFaucetResult(message);
            console.error(message, e);
          }
        }}
        className="btn btn-outline-success">
        Get ERC20 token (Decimal 18)
      </Button>
      <Button
        onClick={async () => {
          try {
            const response = await sendErc20(
              face,
              createSmallDecimalFT('0.01', blockchain!),
              account.address!,
              erc20Decimal6ContractAddressMap[network],
              network
            );

            const receipt = await response?.wait();

            if (receipt?.status) {
              setFaucetResult(`Succeeded to get 0.01 token. (Decimal 6)`);
            } else {
              throw receipt;
            }
          } catch (e) {
            const message = 'Failed to get 0.01 token. (Decimal 6)';
            setFaucetResult(message);
            console.error(message, e);
          }
        }}
        className="btn btn-outline-success">
        Get ERC20 token (Decimal 6)
      </Button>
      <Button
        onClick={async () => {
          try {
            const tokenId = await mintErc721(
              account.address!,
              erc721ContractAddressMap[network],
              network,
              face!
            );
            setFaucetResult(`Succeeded to mint ERC721 token. (Token id: ${tokenId})`);
          } catch (e) {
            const message = 'Failed to mint ERC721 token.';
            setFaucetResult(message);
            console.error(message, e);
          }
        }}
        className="btn btn-outline-success">
        Get ERC721 token
      </Button>
      <Button
        onClick={async () => {
          try {
            const tokenId = await mintErc1155(
              account.address!,
              erc1155ContractAddressMap[network],
              network,
              face!
            );

            setFaucetResult(`Succeeded to mint ERC1155 tokens. (Token id: ${tokenId})`);
          } catch (e) {
            const message = 'Failed to mint ERC1155 tokens.';
            setFaucetResult(message);
            console.error(message, e);
          }
        }}
        className="btn btn-outline-success">
        Get ERC1155 tokens
      </Button>
      <Button
        onClick={async () => {
          if (network != Network.TEZOS && network != Network.GHOSTNET) {
            throw new Error(`FA2 not supported in ${network}`);
          }
          try {
            const response = await sendFa2Ft(
              face,
              createLargeDecimalFT('0.01', blockchain!),
              account.address!,
              fa2FtDecimal18ContractAddressMap[network],
              network
            );

            const receipt = await response?.wait();

            if (receipt?.status) {
              setFaucetResult(`Succeeded to get 0.01 token. (Decimal 18)`);
            } else {
              throw receipt;
            }
          } catch (e) {
            const message = 'Failed to get 0.01 token. (Decimal 18)';
            setFaucetResult(message);
            console.error(message, e);
          }
        }}
        className="btn btn-outline-success">
        Get FA2 FT token (Decimal 18)
      </Button>
      <Button
        onClick={async () => {
          if (network != Network.TEZOS && network != Network.GHOSTNET) {
            throw new Error(`FA2 not supported in ${network}`);
          }
          try {
            const response = await sendFa2Ft(
              face,
              createSmallDecimalFT('0.01', blockchain!),
              account.address!,
              fa2FtDecimal6ContractAddressMap[network],
              network
            );

            const receipt = await response?.wait();

            if (receipt?.status) {
              setFaucetResult(`Succeeded to get 0.01 token. (Decimal 6)`);
            } else {
              throw receipt;
            }
          } catch (e) {
            const message = 'Failed to get 0.01 token. (Decimal 6)';
            setFaucetResult(message);
            console.error(message, e);
          }
        }}
        className="btn btn-outline-success">
        Get FA2 FT token (Decimal 6)
      </Button>
      <Button
        onClick={async () => {
          if (network != Network.TEZOS && network != Network.GHOSTNET) {
            throw new Error(`FA2 not supported in ${network}`);
          }
          try {
            const tokenId = await mintTezosNFT(
              account.address!,
              fa2MftContractAddressMap[network],
              network
            );

            setFaucetResult(`Succeeded to mint FA2 Multi Tokens. (Token id: ${tokenId})`);
          } catch (e) {
            const message = 'Failed to mint FA2 Multi Tokens.';
            setFaucetResult(message);
            console.error(message, e);
          }
        }}
        className="btn btn-outline-success">
        Get FA2 Multi Tokens
      </Button>
    </Box>
  );
}

export default Faucet;
