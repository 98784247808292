import { Env, Network } from '@haechi-labs/face-types';
import { PublicKey } from '@solana/web3.js';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ecdsaPublicKeyToEthAddress, Jwt } from '../../libs/utils';
import { accountAtom, envAtom, faceAtom, networkAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Message from '../common/Message';

const title = 'Verification User';

function UserVerification() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const network = useRecoilValue(networkAtom)!;
  const [jsonWebToken, setJsonWebToken] = useState('');
  const [jwk, setJwk] = useState('');
  const [verificationFail, setVerificationFail] = useState(false);
  const env = useRecoilValue(envAtom);

  function getJwksUrl(): string {
    switch (env) {
      case Env.Dev:
        return 'https://api.dev.facewallet.xyz/jwks';
      case Env.StageTest:
        return 'https://api.stage-test.facewallet.xyz/jwks';
      case Env.ProdTest:
        return 'https://api.test.facewallet.xyz/jwks';
      case Env.ProdMainnet:
        return 'https://api.facewallet.xyz/jwks';
      default:
        return 'http://localhost:8881/jwks';
    }
  }

  async function verify() {
    try {
      const user = await face!.auth.getCurrentUser();
      const token = user!.userVerificationToken;
      const address = account.address?.toLowerCase();
      const userId = account.user!.faceUserId;

      const jwt = new Jwt(getJwksUrl());
      const payload = jwt.getPayload(token);
      if (payload['userId'] !== userId) {
        setVerificationFail(true);
        return;
      }

      const ecdsaPubKey: string = payload['wallets'][0]['ecdsaPublicKey'];
      const eddsaPubKey: string = payload['wallets'][0]['eddsaPublicKey'];

      switch (network) {
        case Network.APTOS || Network.APTOS_TESTNET:
          if (eddsaPubKey !== address) {
            setVerificationFail(true);
            return;
          }
          break;
        case Network.NEAR || Network.NEAR_TESTNET:
          if (eddsaPubKey.substring(2) !== address) {
            setVerificationFail(true);
            return;
          }
          break;
        case Network.SOLANA || Network.SOLANA_DEVNET:
          const publicKey = new PublicKey(Buffer.from(eddsaPubKey!.substring(2), 'hex'));
          if (publicKey.toBase58() !== address) {
            setVerificationFail(true);
            return;
          }
          break;
        default:
          const ethAddress = ecdsaPublicKeyToEthAddress(ecdsaPubKey.substring(2));
          if (ethAddress !== address) {
            setVerificationFail(true);
            return;
          }
      }

      setJsonWebToken(token);
      setJwk(await jwt.getJWK(token));
    } catch (error) {
      console.error(error);
    }
  }

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }
  if (!account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  return (
    <Box title={title}>
      <Message type="info" className="has-text-left">
        <h4 className="has-text-weight-bold">User Info</h4>
        <div>JWT: {jsonWebToken}</div>
        <div>JWK: {jwk}</div>
      </Message>

      <Button onClick={() => verify()}>Verify User</Button>

      {!verificationFail && jsonWebToken && (
        <Message type="info" className="has-text-left">
          <div>Verification Success!</div>
        </Message>
      )}
      {verificationFail && (
        <Message type="danger" className="has-text-left">
          <div>Invalid User</div>
        </Message>
      )}
    </Box>
  );
}

export default UserVerification;
