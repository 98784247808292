import { getMetaMask, getWalletConnect, getWalletConnectLegacy, Kit } from '@haechi-labs/face-kit';
import {
  FaceLoginResponse,
  LoginProvider,
  LoginProviderType,
  Wallet,
} from '@haechi-labs/face-types';
import { isEthlikeBlockchain, networkToBlockchain } from '@haechi-labs/shared';
import { providers } from 'ethers';
import { isHexString } from 'ethers/lib/utils';
import { useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getAccountInfo } from '../../libs/accountInfo';
import { customTokenLogin, customTokenLoginWithToken } from '../../libs/auth';
import {
  accountAtom,
  faceAtom,
  kitAtom,
  loginStatusAtom,
  networkAtom,
  sessionKeyAtom,
  walletAtom,
} from '../../store';
import { privateKeyAtom } from '../../store/privateKeyAtom';
import Box from '../common/Box';
import Button from '../common/Button';
import CheckboxList from '../common/CheckboxList';
import Field from '../common/Field';
import Hr from '../common/Hr';
import Message from '../common/Message';

const wcProjectId = 'c4e1a0d62ff8a6c9211c5d4b12cc0d67';

const title = 'Log in';

function LoginWithFace() {
  const face = useRecoilValue(faceAtom);
  const setAccount = useSetRecoilState(accountAtom);
  const setSession = useSetRecoilState(sessionKeyAtom);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loginStatusAtom);
  const [loginProviders, setLoginProviders] = useState<LoginProviderType[]>([]);
  const [kitOptions, setKitOptions] = useState<string[]>([]);
  const [externalWallets, setExternalWallets] = useState<string[]>([]);
  const [customToken, setCustomToken] = useState<string>('');
  const network = useRecoilValue(networkAtom)!;
  const [prvKey, setPrvKey] = useRecoilState(privateKeyAtom);

  const [kit, setKit] = useRecoilState(kitAtom);
  const [wallet, setWallet] = useRecoilState(walletAtom);

  const handleLogin = (res: FaceLoginResponse | null) => {
    try {
      console.log('Facewallet Login Succeed:', res);
      setSession((res as any)?.session);
      setIsLoggedIn(true);
      getAccountInfoCallback();
    } catch (e) {
      console.error(e);
    }
  };

  const getAccountInfoCallback = useCallback(async () => {
    if (!face) {
      return null;
    }

    const { address, balance, user } = await getAccountInfo(face, network);
    console.group('[Account Information]');
    console.log('Balance:', balance);
    console.log('Address:', address);
    console.log('Current user:', user);
    console.groupEnd();

    setAccount({ address, balance, user });
  }, [face, network, setAccount]);

  const getUserInfoInfinitely = useCallback(async () => {
    if (!face) {
      return null;
    }

    let minimum = Number.MAX_SAFE_INTEGER;
    let maximum = Number.MIN_SAFE_INTEGER;
    let average = 0;
    for (let i = 0; i < 10000; i++) {
      const start = new Date().getTime();
      await face.auth.getCurrentUser();
      const end = new Date().getTime();
      const time = end - start;
      if (time < minimum) {
        minimum = time;
      }
      if (time > maximum) {
        maximum = time;
      }
      average += time;
    }
    average /= 10000;

    console.log('minimum:', minimum);
    console.log('maximum:', maximum);
    console.log('average:', average);
  }, [face]);

  async function signMessage() {
    try {
      const provider = new providers.Web3Provider(
        wallet ? await wallet.connector.getProvider() : face!.getEthLikeProvider(),
        'any'
      );
      const message = 'signMessage test';
      const signer = provider.getSigner();

      return isHexString(message)
        ? await signer.signMessage(Buffer.from(message.slice(2), 'hex'))
        : await signer.signMessage(message);
    } catch (err) {
      console.error(err);
    }
  }

  async function login(hasSign?: boolean) {
    try {
      if (isEthlikeBlockchain(networkToBlockchain(face!.internal.getNetwork()))) {
        face!.getEthLikeProvider().on('connect', (connectInfo) => {
          console.log(`Connected with chainId: ${connectInfo.chainId}`);
        });
        face!.getEthLikeProvider().on('disconnect', (error) => {
          console.log(`Facewallet disconnected err: ${JSON.stringify(error)}`);
        });
      }
      const res = await face?.auth.login(loginProviders.length ? loginProviders : undefined);

      setSession((res as any).session);
      console.log('Login response:', res);
      setIsLoggedIn(true);
      getAccountInfoCallback();

      if (hasSign) {
        await signMessage();
      }
    } catch (e) {
      console.error('Login failed:', e);
    }
  }

  async function socialLogin(provider: LoginProviderType) {
    try {
      if (isEthlikeBlockchain(networkToBlockchain(face!.internal.getNetwork()))) {
        face!.getEthLikeProvider().on('connect', (connectInfo) => {
          console.log(`Connected with chainId: ${connectInfo.chainId}`);
        });
        face!.getEthLikeProvider().on('disconnect', (error) => {
          console.log(`Facewallet disconnected err: ${JSON.stringify(error)}`);
        });
      }
      const res = await face?.auth.directSocialLogin(provider);
      setSession((res as any).session);
      console.log('Social Login response:', res);
      setIsLoggedIn(true);
      getAccountInfoCallback();
    } catch (e) {
      console.error('Social Login failed:', e);
    }
  }

  async function logout() {
    try {
      await face?.auth.logout();
      setIsLoggedIn(false);
      setSession('');
      setAccount({});
    } catch (e) {
      console.error(e);
    }
  }

  async function printIsLoggedIn() {
    if (face == null) {
      console.log('face is null. isloggedin failed');
    } else {
      try {
        const result = await face.auth.isLoggedIn();
        console.log('isLoggedIn', result);
      } catch (e) {
        console.error('isLoggedIn failed:');
        console.error(e);
      }
    }
  }

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }

  const wallets = externalWallets
    .map((w) => {
      switch (w) {
        case 'metaMask':
          return getMetaMask();
        case 'walletConnect':
          return getWalletConnect({ options: { projectId: wcProjectId } });
        case 'walletConnectLegacy':
          return getWalletConnectLegacy();
      }
    })
    .filter((w) => w) as Wallet[];

  return (
    <Box title={title}>
      {isLoggedIn ? (
        <>
          <Message type="info">Log in succeed</Message>
          <Button onClick={getAccountInfoCallback}>Get account information</Button>
          <Button onClick={getUserInfoInfinitely}>Get infinite user info</Button>
        </>
      ) : (
        <>
          <Button onClick={() => login()}>Facewallet login </Button>
          <Button onClick={() => login(true)}>Facewallet login with Sign Request</Button>
          <CheckboxList
            items={Object.values(LoginProvider).map((p) => ({ key: p }))}
            state={loginProviders}
            setState={setLoginProviders as React.Dispatch<React.SetStateAction<string[]>>}
          />
          <Hr />

          <div>Facewallet directSocialLogin with Provider</div>
          <Button onClick={() => socialLogin('google.com')}>Google login</Button>
          <Button onClick={() => socialLogin('apple.com')}>Apple login</Button>
          <Button onClick={() => socialLogin('facebook.com')}>Facebook login</Button>
          <Button onClick={() => socialLogin('twitter.com')}>Twitter login</Button>
          <Button onClick={() => socialLogin('kakao.com')}>Kakao login</Button>
          <Button onClick={() => socialLogin('discord.com')}>Discord login</Button>
          <Hr />

          <div>OAuth custom login with Provider</div>
          <Field label="Private key">
            <input
              name="prv-key"
              className="input"
              type="text"
              onChange={(e) => setPrvKey(e.target.value)}
              value={prvKey}
            />
          </Field>
          <Button onClick={() => customTokenLogin(face, 'google.com', prvKey, handleLogin)}>
            (Custom Token) Google login
          </Button>
          <Button onClick={() => customTokenLogin(face, 'apple.com', prvKey, handleLogin)}>
            (Custom Token) Apple login
          </Button>
          <Button onClick={() => customTokenLogin(face, 'facebook.com', prvKey, handleLogin)}>
            (Custom Token) Facebook login
          </Button>
          <Button onClick={() => customTokenLogin(face, 'kakao.com', prvKey, handleLogin)}>
            (Custom Token) Kakao login
          </Button>
          <Button onClick={() => customTokenLogin(face, 'twitter.com', prvKey, handleLogin)}>
            (Custom Token) Twitter login
          </Button>
          <Button onClick={() => customTokenLogin(face, 'discord.com', prvKey, handleLogin)}>
            (Custom Token) Discord login
          </Button>
          <Button onClick={() => printIsLoggedIn()}>Print IsLoggedIn</Button>

          <Field label="Token">
            <input
              name="custom-token-login"
              className="input"
              onChange={(e) => setCustomToken(e.target.value)}
              value={customToken}
            />
          </Field>
          <Button
            onClick={() =>
              customTokenLoginWithToken(face, 'twitter.com', customToken, prvKey, handleLogin)
            }>
            Custom Token Login (only twitter)
          </Button>
        </>
      )}
      <Button onClick={logout}>Log out</Button>

      <Hr />

      <h2 className="box__title title is-4">Facewallet Kit</h2>
      {!kit && (
        <>
          <CheckboxList
            items={[
              { key: 'metaMask', label: 'MetaMask' },
              { key: 'walletConnect', label: 'WalletConnect (v2)' },
              { key: 'walletConnectLegacy', label: 'WalletConnect (v1)' },
            ]}
            state={externalWallets}
            setState={setExternalWallets}
          />
          <CheckboxList
            items={[
              { key: 'expanded', label: 'Expand External Wallet' },
              { key: 'emptyProviders', label: 'Empty Providers' },
              { key: 'emptyExternalWallet', label: 'Empty External Wallet' },
            ]}
            state={kitOptions}
            setState={setKitOptions}
          />
        </>
      )}
      {!kit && (
        <Button
          onClick={async () => {
            if (!face) {
              return;
            }
            const kit = new Kit(face, {
              providers: kitOptions.includes('emptyProviders')
                ? []
                : loginProviders.length
                ? loginProviders
                : undefined,
              externalWalletOptions: {
                wallets: kitOptions.includes('emptyExternalWallet')
                  ? []
                  : wallets.length
                  ? wallets
                  : [getMetaMask(), getWalletConnect({ options: { projectId: wcProjectId } })],
                expanded: kitOptions.includes('expanded'),
              },
            });
            setKit(kit);

            try {
              const isConnected = await kit.isConnected();
              if (isConnected) {
                const connectedWallet = await kit.connect();
                console.log('Kit reconnected!', connectedWallet);
                setWallet(connectedWallet);
                setAccount({ address: await connectedWallet.connector.getAccount() });
              }
            } catch (e) {
              console.error('Failed automatically connecting wallet by Kit:', e);
            }
          }}>
          Initialize Kit
        </Button>
      )}
      {kit && (
        <>
          <Button
            onClick={async () => {
              if (!kit) {
                return;
              }

              try {
                const connectedWallet = await kit.connect();
                setWallet(connectedWallet);
                setAccount({ address: await connectedWallet.connector.getAccount() });
              } catch (e) {
                console.error('Failed connecting wallet by Kit:', e);
              }
            }}>
            Connect wallet by Kit
          </Button>
          <Button
            onClick={async () => {
              console.log('isConnected', await kit.isConnected());
            }}>
            isConnected
          </Button>
          <Button
            onClick={async () => {
              console.log('ConnectedWallet', kit.getConnectedWallet());
            }}>
            Get ConnectedWallet
          </Button>
          <Button
            onClick={() => {
              kit.disconnect();
              setWallet(null);
              setAccount({});
            }}>
            disconnect wallet
          </Button>
        </>
      )}
    </Box>
  );
}

export default LoginWithFace;
