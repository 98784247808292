import { Blockchain, JsonRpcMethod, TezosTransferCoinRequest } from '@haechi-labs/face-types';

import { Internal } from '../internal';

export class TezosWallet {
  private internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  async getAddresses(): Promise<string[]> {
    return await this.internal.getAddresses(Blockchain.TEZOS);
  }

  async getPublicKeys(): Promise<string> {
    const result = await this.internal.sendRpc<string>({
      method: JsonRpcMethod.tezos_publicKey,
    });
    return result!;
  }

  async transferCoin(request: TezosTransferCoinRequest) {
    const publicKey = (await this.getPublicKeys())[0];
    const senderAddress = (await this.getAddresses())[0];
    const result = await this.internal.sendRpc<string>({
      method: JsonRpcMethod.tezos_transferCoin,
      params: [publicKey, senderAddress, request],
    });
    return result!;
  }
}
