import { JsonRpcMethod, TezosTransferFa2Params } from '@haechi-labs/face-types';

import { Internal } from '../internal';
import { TezosWallet } from './TezosWallet';

export class TezosContract {
  public methods: Method;

  constructor(internal: Internal, wallet: TezosWallet, contractAddress: string) {
    this.methods = new Method(internal, wallet, contractAddress);
  }
}

class Method {
  private internal: Internal;
  private wallet: TezosWallet;
  private readonly contractAddress: string;

  constructor(internal: Internal, wallet: TezosWallet, contractAddress: string) {
    this.internal = internal;
    this.wallet = wallet;
    this.contractAddress = contractAddress;
  }

  async transfer(params: TezosTransferFa2Params[]) {
    const publicKey = (await this.wallet.getPublicKeys())[0];
    const senderAddress = (await this.wallet.getAddresses())[0];
    const result = await this.internal.sendRpc<string>({
      method: JsonRpcMethod.tezos_transferFA2,
      params: [publicKey, senderAddress, this.contractAddress, params],
    });
    return result!;
  }
}
