import {
  assert,
  assertArgument,
  Blockchain,
  Env,
  JsonRpcMethod,
  Network,
  unsupportedChainError,
} from '@haechi-labs/face-types';
import { isEthlikeBlockchain, isSupportedNetwork, networkToBlockchain } from '@haechi-labs/shared';

import { Auth } from './auth';
import { Aptos } from './blockchain/Aptos';
import { Bora } from './blockchain/Bora';
import { Hedera } from './blockchain/Hedera';
import { Near } from './blockchain/Near';
import { Solana } from './blockchain/Solana';
import { Tezos } from './blockchain/Tezos';
import { Internal } from './internal';
import { Provider } from './provider';
import { getNetwork } from './utils';
import { Wallet } from './wallet';
import { WalletConnect } from './walletConnect';

export { Network };

export interface NotificationOptions {
  type: 'toast' | 'none';
}

export interface FaceConfig {
  apiKey: string;
  network?: Network | number;
  notificationOptions?: NotificationOptions;
}

export class Face {
  public readonly internal: Internal;
  // public network: Network;
  public wc: WalletConnect;
  public auth: Auth;
  public solana: Solana;
  public near: Near;
  public wallet: Wallet;
  public bora: Bora;
  public aptos: Aptos;
  public hedera: Hedera;
  public tezos: Tezos;

  constructor({ apiKey, network, notificationOptions, ...rest }: FaceConfig) {
    assertArgument(apiKey, typeof apiKey === 'string', 'apiKey');
    assertArgument(network, true, 'network');

    const _network = getNetwork(network!);
    assert(isSupportedNetwork(_network, ['in-app']), unsupportedChainError);

    // env, iframeUrl은 내부적으로 사용됨. 비어있으면 iframeUrl이 PM으로 설정됨
    this.internal = new Internal({
      apiKey,
      network: _network,
      env: (rest as { env?: Env })?.env,
      iframeUrl: (rest as { iframeUrl?: string })?.iframeUrl,
      notificationOptions,
      face: this,
    });
    this.auth = new Auth(this.internal);
    this.wc = new WalletConnect(this.internal);
    this.solana = new Solana(this.internal);
    this.near = new Near(this.internal);
    this.wallet = new Wallet(this.internal);
    this.bora = new Bora(this.internal);
    this.aptos = new Aptos(this.internal);
    this.hedera = new Hedera(this.internal);
    this.tezos = new Tezos(this.internal);
  }

  async ready(): Promise<void> {
    return this.internal.ready();
  }

  getEthLikeProvider(): Provider {
    assert(
      isEthlikeBlockchain(networkToBlockchain(this.internal.getNetwork())),
      unsupportedChainError
    );

    return new Provider(this.internal);
  }

  getAddresses = async (blockchain?: Blockchain): Promise<string[]> => {
    assertArgument(blockchain, blockchain && Blockchain[blockchain], 'blockchain', false);

    return await this.internal.getAddresses(blockchain);
  };

  getNetwork = (): Network => {
    return this.internal.getNetwork();
  };

  getChainId = async (): Promise<number> => {
    return Number(await this.internal.sendRpc({ method: JsonRpcMethod.eth_chainId, params: [] }));
  };

  async switchNetwork(network: Network | number | string) {
    return await this.internal.switchNetwork(network);
  }
}
