import { Face, Network } from '@haechi-labs/face-sdk';
import { getNetworkConfig } from '@haechi-labs/shared';
import BN from 'bn.js';
import { BigNumber, ethers, TypedDataField, utils } from 'ethers';
import jwkToPem from 'jwk-to-pem';
import jwt_decode from 'jwt-decode';

import { ERC20_ABI, ERC721_TRANSFER_ABI, ERC1155_TRANSFER_ABI } from './abi';

export function makeErc20Data(functionFragment: string, to: string, value: BigNumber) {
  const ethersInterface = new ethers.utils.Interface(ERC20_ABI);
  return ethersInterface.encodeFunctionData(functionFragment, [to, value]);
}

export function makeErc721Data(
  functionFragment: string,
  from: string,
  to: string,
  tokenId: BigNumber
) {
  const ethersInterface = new ethers.utils.Interface(ERC721_TRANSFER_ABI);
  return ethersInterface.encodeFunctionData(functionFragment, [from, to, tokenId]);
}

export function makeErc1155Data(
  functionFragment: string,
  from: string,
  to: string,
  tokenId: BigNumber,
  amount: BigNumber
) {
  const ethersInterface = new ethers.utils.Interface(ERC1155_TRANSFER_ABI);
  return ethersInterface.encodeFunctionData(functionFragment, [from, to, tokenId, amount, '0x00']);
}

export function getExplorerUrl(network: Network, transactionHash: string): string {
  const networkConfig = getNetworkConfig(network);
  if (!networkConfig) return '';

  if (typeof networkConfig.explorerUrl === 'function') {
    return networkConfig.explorerUrl(transactionHash, 'tx');
  }
  return `${networkConfig.explorerUrl}/tx/${transactionHash}`;
}

export function getProvider(network: Network) {
  switch (network) {
    case Network.BORA:
      return process.env.BORA_MAINNET_NODE_ENDPOINT!;
    case Network.BORA_TESTNET:
      return process.env.BORA_TESTNET_NODE_ENDPOINT!;
    default:
      const config = getNetworkConfig(network);
      if (!config || !config.providerUrl)
        throw Error(`cannot resolve provider with network : ${network}`);
      return config.providerUrl;
  }
}

export function getEthersProvider(network: Network, face: Face) {
  if (face.getNetwork() !== network) {
    throw new Error(
      `network doesn't match with face's network : ${network} !== ${face.getNetwork()}`
    );
  }
  return new ethers.providers.Web3Provider(face.getEthLikeProvider());
}

export async function getGasPrice(network: Network, face: Face): Promise<string> {
  const provider = getEthersProvider(network, face);
  return (await provider.getGasPrice()).toHexString();
}

export function calcNearTgas(tgas: number): BN {
  // 1 tgas == 0.0001 near
  // 1 tgas === 1000000000000 BN
  tgas *= 1000000000000;
  return new BN(`${tgas}`, 10);
}

export function filterTypes(
  types: Record<string, Array<TypedDataField>>,
  currentTypeName: string
): Record<string, Array<TypedDataField>> {
  let ret: Record<string, Array<TypedDataField>> = {};
  ret[currentTypeName] = types[currentTypeName];

  for (const iterator of types[currentTypeName]) {
    const typeName = (iterator.type as string).split('[]')[0];
    if (typeName in ret) return ret;
    if (typeName in types) {
      ret = { ...ret, ...filterTypes(types, typeName) };
    }
  }

  return ret;
}

export class Jwt {
  private jwksUrl: string;

  constructor(jwksUrl: string) {
    this.jwksUrl = jwksUrl;
  }

  private async fetchMatchingJwk(kid: string): Promise<any> {
    const response = await fetch(this.jwksUrl);
    const { keys } = await response.json();
    const matchingKey = keys.find((key: Record<string, string>) => key.kid === kid);
    if (!matchingKey) {
      throw new Error(`Key with kid ${kid} not found`);
    }
    return matchingKey;
  }

  public getPayload(token: string): any {
    return jwt_decode(token);
  }

  public async getJWK(token: string): Promise<string> {
    const header = jwt_decode<{ kid: string }>(token, { header: true });
    const jwk = await this.fetchMatchingJwk(header.kid);
    return jwkToPem(jwk);
  }
}

export const ecdsaPublicKeyToEthAddress = (publicKey: string): string => {
  const publicKeyBytes = Buffer.from(publicKey, 'hex');
  const addressBytes = utils.keccak256(publicKeyBytes);
  const address = utils.getAddress('0x' + addressBytes.slice(26));
  return address.toLowerCase();
};
