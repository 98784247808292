import { TransactionRequest } from '@ethersproject/abstract-provider';
import { Signer } from '@ethersproject/abstract-signer';
import { Face } from '@haechi-labs/face-sdk';
import { Blockchain, Network } from '@haechi-labs/face-types';
import { isEthlikeBlockchain, networkToBlockchain } from '@haechi-labs/shared';
import { Client, PrivateKey, TokenMintTransaction, TransferTransaction } from '@hashgraph/sdk';
import * as fa2 from '@oxheadalpha/fa2-interfaces';
import { tezosApi, TokenMetadataInternal } from '@oxheadalpha/fa2-interfaces';
import * as solanaSplToken from '@solana/spl-token';
import * as solanaWeb3 from '@solana/web3.js';
import { MichelsonMap, TezosToolkit } from '@taquito/taquito';
import * as tezosAPI from '@tzkt/sdk-api';
import { operationsGetByHash, tokensGetTokenBalances, tokensGetTokens } from '@tzkt/sdk-api';
import { BN } from 'bn.js';
import { Buffer } from 'buffer';
import { ethers } from 'ethers';
import { poll } from 'ethers/lib/utils';
import { sha256 } from 'js-sha256';
import * as nearAPI from 'near-api-js';

import {
  getNearRichWallet,
  getSolanaRichWallet,
  getTezosWallet,
  RICH_WALLET_PRIVATE_KEY,
} from '../config/faucetAccount';
import { config as nearConfig } from '../config/near';
import { getTezosApiUrl } from '../config/tezos';
import { ERC20_ABI, ERC721_ABI, ERC1155_ABI } from './abi';
import { Coin, SentTransaction } from './types';
import { getEthersProvider, getProvider } from './utils';

export async function sendPlatformCoin(
  face: Face,
  amount: Coin,
  to: string,
  network: Network
): Promise<SentTransaction | undefined> {
  const blockchain = networkToBlockchain(network);
  if (isEthlikeBlockchain(blockchain)) {
    return sendEthereumLikePlatformCoin(amount, to, network, face);
  }
  if (blockchain === Blockchain.SOLANA) {
    return sendSolanaPlatformCoin(amount, to, network);
  }
  if (blockchain === Blockchain.NEAR) {
    return sendNearPlatformCoin(amount, to, network);
  }
  if (blockchain === Blockchain.TEZOS) {
    return sendTezosPlatformCoin(amount, to, network);
  }
  throw new Error('invalid blockchain');
}

async function sendTezosPlatformCoin(amount: Coin, to: string, network: Network) {
  const signer = await getTezosWallet();
  const tezos = new TezosToolkit(getProvider(network));
  tezos.setProvider({ signer: signer });
  const op = await tezos.wallet
    .transfer({
      to: to,
      amount: amount.toNonDecimalAmountAsNumber(),
    })
    .send();
  return {
    hash: op.opHash,
    wait: async () => {
      await op.confirmation();
      return await poll<{ status: boolean; internal: any }>(async (): Promise<any> => {
        try {
          const result: any[] = await operationsGetByHash(op.opHash);
          if (result.length == 0) {
            return null;
          }
          return {
            status: result.find((r) => r['errors'] != null) ? false : true,
            internal: result,
          };
        } catch (e) {
          console.debug('tezos polling', e);
          return undefined;
        }
      });
    },
  };
}

async function sendEthereumLikePlatformCoin(
  amount: Coin,
  to: string,
  network: Network,
  face: Face
): Promise<SentTransaction | undefined> {
  const provider = getEthersProvider(network, face);

  return sendEthTransaction(network, new ethers.Wallet(RICH_WALLET_PRIVATE_KEY, provider), {
    to: to,
    value: amount.toHexAmount(),
    gasLimit: 1000000,
  });
}

async function sendSolanaPlatformCoin(amount: Coin, to: string, network: Network) {
  const keypair = getSolanaRichWallet();
  const connection = new solanaWeb3.Connection(getProvider(network), 'confirmed');
  const recentBlockhash = await connection.getLatestBlockhash('finalized');
  const message = new solanaWeb3.TransactionMessage({
    payerKey: keypair.publicKey,
    instructions: [
      solanaWeb3.SystemProgram.transfer({
        fromPubkey: keypair.publicKey,
        toPubkey: new solanaWeb3.PublicKey(to),
        lamports: amount.toDecimalAmountAsNumber(),
      }),
    ],
    recentBlockhash: recentBlockhash.blockhash,
  });
  const transaction = new solanaWeb3.VersionedTransaction(message.compileToV0Message());
  transaction.sign([keypair]);

  const result = await connection.sendTransaction(transaction);
  return {
    hash: result,
    wait: async () => {
      await connection.confirmTransaction({
        signature: result,
        ...recentBlockhash,
      });
      const confirmedTx = await connection.getTransaction(result, {
        maxSupportedTransactionVersion: 0,
      });
      console.log('confirmedTx', confirmedTx);
      return {
        status: confirmedTx?.meta?.err == null,
        internal: confirmedTx,
      };
    },
  };
}

async function sendNearPlatformCoin(amount: Coin, to: string, network: Network) {
  const keypair = getNearRichWallet();
  const senderAddress = Buffer.from(keypair.getPublicKey().data).toString('hex');
  const publicKey = keypair.getPublicKey();
  const provider = new nearAPI.providers.JsonRpcProvider({ url: getProvider(network) });
  const accessKey = await provider.query<{
    block_height: number;
    block_hash: string;
    nonce: number;
  }>(`access_key/${senderAddress}/${publicKey.toString()}`, '');

  const nonce = accessKey.nonce + 1;
  const actions = [nearAPI.transactions.transfer(amount.toDecimalAmountAsString() as any)];

  const near = await nearAPI.connect(nearConfig(network));
  const status = await near.connection.provider.status();
  const blockHash = status.sync_info.latest_block_hash;
  const serializedBlockHash = nearAPI.utils.serialize.base_decode(blockHash);

  const tx = nearAPI.transactions.createTransaction(
    senderAddress,
    publicKey,
    to,
    nonce,
    actions,
    serializedBlockHash
  );

  const serializedTx = nearAPI.utils.serialize.serialize(nearAPI.transactions.SCHEMA, tx);
  const serializedTxHash = new Uint8Array(sha256.array(serializedTx));
  const signature = keypair.sign(serializedTxHash);

  const signedTransaction = new nearAPI.transactions.SignedTransaction({
    transaction: tx,
    signature: new nearAPI.transactions.Signature({
      keyType: signature.publicKey.keyType,
      data: signature.signature,
    }),
  });

  const result = await provider.sendTransaction(signedTransaction);
  const hash = result.transaction.hash;

  return {
    hash,
    wait: async () => {
      return await poll<{ status: boolean; internal: any }>(async (): Promise<any> => {
        try {
          const receipt = await provider.txStatus(hash, senderAddress);
          return {
            status: Object.keys(receipt!.status as any).includes('SuccessValue'),
            internal: receipt,
          };
        } catch (e) {
          console.debug('near polling', e);
          return undefined;
        }
      });
    },
  };
}

export async function sendErc20(
  face: Face,
  amount: Coin,
  to: string,
  contractAddress: string,
  network: Network
): Promise<SentTransaction | undefined> {
  const blockchain = networkToBlockchain(network);
  if (isEthlikeBlockchain(blockchain)) {
    return sendEthereumLikeErc20(amount, to, contractAddress, network, face);
  }
  if (blockchain === Blockchain.SOLANA) {
    return sendSolanaFT(amount, to, contractAddress, network);
  }
  if (blockchain === Blockchain.NEAR) {
    return sendNearFT(amount, to, contractAddress, network);
  }
  throw new Error('invalid blockchain');
}

export async function sendFa2Ft(
  face: Face,
  amount: Coin,
  to: string,
  contractAddress: string,
  network: Network
): Promise<SentTransaction | undefined> {
  const signer = await getTezosWallet();
  const tezos = new TezosToolkit(getProvider(network));
  tezos.setProvider({ signer: signer });
  tezosAPI.defaults.baseUrl = getTezosApiUrl(network);
  const tokens = await tokensGetTokens({
    contract: {
      eq: contractAddress,
    },
  });
  if (tokens.length != 1) {
    throw new Error(
      `Invalid FA2 FT! given address ${contractAddress} has ${tokens.length} tokens.`
    );
  }

  const tokenId = tokens[0].tokenId!;
  const contract = await tezos.wallet.at(contractAddress);
  const op = await contract.methods
    .transfer([
      {
        from_: await signer.publicKeyHash(),
        txs: [
          {
            to_: to,
            token_id: tokenId,
            amount: amount.toDecimalAmountAsString(),
          },
        ],
      },
    ])
    .send();

  return {
    hash: op.opHash,
    wait: async () => {
      await op.confirmation();
      return await poll<{ status: boolean; internal: any }>(async (): Promise<any> => {
        try {
          const result: any[] = await operationsGetByHash(op.opHash);
          if (result.length == 0) {
            return null;
          }
          return {
            status: result.find((r) => r['errors'] != null) ? false : true,
            internal: result,
          };
        } catch (e) {
          console.debug('tezos polling', e);
          return undefined;
        }
      });
    },
  };
}

async function sendEthereumLikeErc20(
  amount: Coin,
  to: string,
  contractAddress: string,
  network: Network,
  face: Face
) {
  const provider = getEthersProvider(network, face);
  const signer = new ethers.Wallet(RICH_WALLET_PRIVATE_KEY, provider);
  const contract = new ethers.Contract(contractAddress, ERC20_ABI, signer);

  return sendEthTransaction(network, signer, {
    to: contractAddress,
    value: 0,
    data: contract.interface.encodeFunctionData('transfer', [to, amount.toHexAmount()]),
    gasLimit: 1000000,
  });
}

async function sendSolanaFT(amount: Coin, to: string, contractAddress: string, network: Network) {
  const keypair = getSolanaRichWallet();
  const connection = new solanaWeb3.Connection(getProvider(network), 'confirmed');
  const recentBlockhash = await connection.getLatestBlockhash('finalized');
  const token = new solanaWeb3.PublicKey(contractAddress);

  console.log('get or carete from account');
  const fromTokenAccount = await solanaSplToken.getOrCreateAssociatedTokenAccount(
    connection,
    keypair,
    token,
    keypair.publicKey
  );

  console.log('get or carete to account');
  const toTokenAccount = await solanaSplToken.getOrCreateAssociatedTokenAccount(
    connection,
    keypair,
    token,
    new solanaWeb3.PublicKey(to)
  );

  console.log('send solana spl token');
  const txSignature = await solanaSplToken.transfer(
    connection,
    keypair,
    fromTokenAccount.address,
    toTokenAccount.address,
    keypair.publicKey,
    amount.toDecimalAmountAsNumber()
  );

  return {
    hash: txSignature,
    wait: async () => {
      await connection.confirmTransaction({
        signature: txSignature,
        ...recentBlockhash,
      });
      const confirmedTx = await connection.getTransaction(txSignature, {
        maxSupportedTransactionVersion: 0,
      });
      console.log('confirmedTx', confirmedTx);
      return {
        status: confirmedTx?.meta?.err == null,
        internal: confirmedTx,
      };
    },
  };
}

export async function mintErc721(
  to: string,
  contractAddress: string,
  network: Network,
  face: Face
) {
  const provider = getEthersProvider(network, face);
  const signer = new ethers.Wallet(RICH_WALLET_PRIVATE_KEY, provider);
  const contract = new ethers.Contract(contractAddress, ERC721_ABI, signer);

  return await new Promise(async (resolve) => {
    await sendEthTransaction(network, signer, {
      to: contractAddress,
      value: 0,
      data: contract.interface.encodeFunctionData('mintAuto', [to]),
      gasLimit: 1000000,
    });
    contract.on('Transfer', (_from: string, _to: string, _value: string) => {
      if (to.toLowerCase() === _to.toLowerCase()) {
        contract.removeAllListeners();
        resolve(Number(_value));
      }
    });
  });
}

export async function mintTezosNFT(to: string, contractAddress: string, network: Network) {
  const signer = await getTezosWallet();
  const tezos = new TezosToolkit(getProvider(network));
  tezos.setProvider({ signer: signer });
  tezosAPI.defaults.baseUrl = getTezosApiUrl(network);
  const contract = await tezosApi(tezos).at(contractAddress);
  const nft = contract.asNft().withMint();
  const tokenId = (
    await tokensGetTokenBalances({
      tokenContract: {
        eq: contractAddress,
      },
    })
  ).length;
  const metadata: TokenMetadataInternal = createTezosNftMetadata(tokenId, {
    name: `FACE NFT ITEM-${tokenId}`,
    decimals: '0',
    description: 'description',
    thumbnailUri: 'https://haechi-labs.github.io/henesis-contract-misc/henesis-nft/1.jpg',
  });
  const op = await fa2.runMethod(
    nft.mint([
      {
        owner: to,
        tokens: [metadata],
      },
    ])
  );
  await op.confirmation();
  return tokenId;
}

function createTezosNftMetadata(tokenId: number, data: object): TokenMetadataInternal {
  const metadataMap = new MichelsonMap<string, string>();
  for (const k in data) {
    metadataMap.set(k, toMichelsonBytes((data as any)[k]));
  }
  return {
    token_id: tokenId,
    token_info: metadataMap,
  };
}

function toMichelsonBytes(val: string) {
  const encoder = new TextEncoder();
  return Buffer.from(encoder.encode(val)).toString('hex');
}

export async function mintErc1155(
  to: string,
  contractAddress: string,
  network: Network,
  face: Face
) {
  const provider = getEthersProvider(network, face);
  const signer = new ethers.Wallet(RICH_WALLET_PRIVATE_KEY, provider);
  const contract = new ethers.Contract(contractAddress, ERC1155_ABI, signer);

  return await new Promise(async (resolve) => {
    await sendEthTransaction(network, signer, {
      to: contractAddress,
      value: 0,
      data: contract.interface.encodeFunctionData('mintAuto', [to, 10000, '0x00']),
      gasLimit: 1000000,
    });
    contract.on('TransferSingle', (_operator: string, _from: string, _to: string, _id: string) => {
      if (to.toLowerCase() === _to.toLowerCase()) {
        contract.removeAllListeners();
        resolve(Number(_id));
      }
    });
  });
}

async function sendNearFT(amount: Coin, to: string, contractAddress: string, network: Network) {
  const keypair = getNearRichWallet();
  const senderAddress = Buffer.from(keypair.getPublicKey().data).toString('hex');
  const publicKey = keypair.getPublicKey();
  const provider = new nearAPI.providers.JsonRpcProvider({ url: getProvider(network) });
  const accessKey = await provider.query<{
    block_height: number;
    block_hash: string;
    nonce: number;
  }>(`access_key/${senderAddress}/${publicKey.toString()}`, '');

  const nonce = accessKey.nonce + 1;
  const actions = [
    nearAPI.transactions.functionCall(
      'storage_deposit',
      {
        account_id: to,
        amount: '0.00235',
      },
      new BN('100000000000000', 10),
      new BN(ethers.utils.parseUnits('0.00235', 24).toString())
    ),
    nearAPI.transactions.functionCall(
      'ft_transfer',
      {
        receiver_id: to,
        amount: amount.toDecimalAmountAsString(),
      },
      new BN('100000000000000', 10),
      new BN('1', 10)
    ),
  ];

  const near = await nearAPI.connect(nearConfig(network));
  const status = await near.connection.provider.status();
  const blockHash = status.sync_info.latest_block_hash;
  const serializedBlockHash = nearAPI.utils.serialize.base_decode(blockHash);

  const tx = nearAPI.transactions.createTransaction(
    senderAddress,
    publicKey,
    contractAddress,
    nonce,
    actions,
    serializedBlockHash
  );

  const serializedTx = nearAPI.utils.serialize.serialize(nearAPI.transactions.SCHEMA, tx);
  const serializedTxHash = new Uint8Array(sha256.array(serializedTx));
  const signature = keypair.sign(serializedTxHash);

  const signedTransaction = new nearAPI.transactions.SignedTransaction({
    transaction: tx,
    signature: new nearAPI.transactions.Signature({
      keyType: signature.publicKey.keyType,
      data: signature.signature,
    }),
  });

  const result = await provider.sendTransaction(signedTransaction);
  const hash = result.transaction.hash;

  return {
    hash,
    wait: async () => {
      return await poll<{ status: boolean; internal: any }>(async (): Promise<any> => {
        try {
          const receipt = await provider.txStatus(hash, senderAddress);
          return {
            status: Object.keys(receipt!.status as any).includes('SuccessValue'),
            internal: receipt,
          };
        } catch (e) {
          console.debug('poll error', e);
          return undefined;
        }
      });
    },
  };
}

async function sendEthTransaction(
  network: Network,
  signer: Signer,
  tx: TransactionRequest
): Promise<SentTransaction | undefined> {
  const feeData = await signer.getFeeData();
  if (feeData.maxFeePerGas != null && feeData.maxPriorityFeePerGas != null) {
    tx.maxFeePerGas = feeData.maxFeePerGas;
    tx.maxPriorityFeePerGas = feeData.maxPriorityFeePerGas;
  } else if (feeData.gasPrice != null && !feeData.gasPrice.isZero()) {
    tx.gasPrice = feeData.gasPrice;
  }

  // When DeFiVerse fixes eth_gasPrice method, remove this!
  // https://haechilabs.slack.com/archives/C03EVTP0RSB/p1698046736765379?thread_ts=1698046691.819009&cid=C03EVTP0RSB
  // The value '0xba43b7400' (decimal: 50000000000) comes from the recommendation by the DeFiVerse
  const blockchain = networkToBlockchain(network);
  if (blockchain == Blockchain.DEFI_VERSE) {
    tx.gasPrice = '0xba43b7400';
  }

  const result = await signer.sendTransaction(tx);

  return {
    hash: result.hash,
    wait: async () => {
      const receipt = await result.wait();
      return {
        status: receipt.status === 1,
        internal: receipt,
      };
    },
  };
}
