import {
  assertArgument,
  BoraPortalConnectRequest,
  FaceIdTokenResponse,
  FaceLoginResponse,
  LoginProvider,
  LoginProviderType,
  LoginWithAccessTokenRequest,
  LoginWithIdTokenRequest,
  ProviderRpcError,
} from '@haechi-labs/face-types';
import { getChainId, isEthlikeNetwork } from '@haechi-labs/shared';
import { ethers } from 'ethers';

import eventEmitter from './events';
import { Internal } from './internal';

export class Auth {
  private readonly internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  private emitLoginEventsOnlyEthlike() {
    if (isEthlikeNetwork(this.internal.getNetwork())) {
      const chainId = ethers.utils.hexlify(getChainId(this.internal.getNetwork()));
      eventEmitter.emit('connect', { chainId });
    }
  }

  private emitLogoutEventsOnlyEthlike() {
    if (isEthlikeNetwork(this.internal.getNetwork())) {
      const error: ProviderRpcError = {
        name: 'disconnect',
        code: 4900,
        message: 'facewallet logout',
      };
      eventEmitter.emit('disconnect', error);
    }
  }

  async login(providers?: LoginProviderType[]): Promise<FaceLoginResponse | null> {
    assertArgument(
      providers,
      Array.isArray(providers) &&
        providers.length > 0 &&
        [...providers].every((provider) => Object.values(LoginProvider).includes(provider as any)),
      'providers',
      false
    );
    const res = await this.internal.loginWithCredential(providers);
    this.emitLoginEventsOnlyEthlike();

    return res;
  }

  async directSocialLogin(provider: LoginProviderType): Promise<FaceLoginResponse | null> {
    assertArgument(provider, typeof provider === 'string', 'provider');

    const res = await this.internal.directSocialLogin(provider);
    this.emitLoginEventsOnlyEthlike();
    return res;
  }

  async getIdToken(
    provider: LoginProviderType,
    accessToken: string
  ): Promise<FaceIdTokenResponse | null> {
    assertArgument(provider, typeof provider === 'string', 'provider');
    assertArgument(accessToken, typeof accessToken === 'string', 'accessToken');

    return this.internal.getIdToken(provider, accessToken);
  }

  async loginWithIdToken(
    loginWithIdTokenRequest: LoginWithIdTokenRequest
  ): Promise<FaceLoginResponse | null> {
    assertArgument(
      loginWithIdTokenRequest,
      loginWithIdTokenRequest.idToken && loginWithIdTokenRequest.sig,
      'loginWithIdTokenRequest'
    );

    const res = await this.internal.loginWithIdToken(loginWithIdTokenRequest);
    this.emitLoginEventsOnlyEthlike();
    return res;
  }

  async loginWithAccessToken(
    loginWithAccessTokenRequest: LoginWithAccessTokenRequest
  ): Promise<FaceLoginResponse | null> {
    assertArgument(
      loginWithAccessTokenRequest,
      loginWithAccessTokenRequest.accessToken && loginWithAccessTokenRequest.sig,
      'loginWithIdTokenRequest'
    );

    const res = await this.internal.loginWithAccessToken(loginWithAccessTokenRequest);
    this.emitLoginEventsOnlyEthlike();
    return res;
  }

  async boraLogin(
    connectRequest: BoraPortalConnectRequest,
    providers?: LoginProviderType[]
  ): Promise<FaceLoginResponse | null> {
    assertArgument(
      providers,
      Array.isArray(providers) &&
        providers.length > 0 &&
        [...providers].every((provider) => Object.values(LoginProvider).includes(provider as any)),
      'providers',
      false
    );
    const res = await this.internal.boraLogin(connectRequest, providers);
    this.emitLoginEventsOnlyEthlike();

    return res;
  }

  async boraDirectSocialLogin(
    connectRequest: BoraPortalConnectRequest,
    provider: LoginProviderType
  ): Promise<FaceLoginResponse | null> {
    assertArgument(provider, typeof provider === 'string', 'provider');

    const res = await this.internal.boraDirectSocialLogin(connectRequest, provider);
    this.emitLoginEventsOnlyEthlike();
    return res;
  }

  async boraLoginWithIdToken(
    connectRequest: BoraPortalConnectRequest,
    loginWithIdTokenRequest: LoginWithIdTokenRequest
  ): Promise<FaceLoginResponse | null> {
    assertArgument(
      loginWithIdTokenRequest,
      loginWithIdTokenRequest.idToken && loginWithIdTokenRequest.sig,
      'loginWithIdTokenRequest'
    );

    const res = await this.internal.boraLoginWithIdToken(connectRequest, loginWithIdTokenRequest);
    this.emitLoginEventsOnlyEthlike();
    return res;
  }

  async logout(): Promise<void> {
    await this.internal.logout();

    this.emitLogoutEventsOnlyEthlike();
  }

  async getCurrentUser(): Promise<FaceLoginResponse | null> {
    return await this.internal.getCurrentUser();
  }

  async isLoggedIn(): Promise<boolean> {
    return await this.internal.isLoggedIn();
  }

  async getUserVerificationToken(): Promise<string> {
    return await this.internal.getUserVerificationToken();
  }
}
