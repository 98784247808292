import { bsc, bscTestnet, mainnet, polygon, sepolia } from '@wagmi/chains';

export const amoy = {
  id: 80002,
  name: 'POLYGON AMOY TESTNET',
  // network: 'maticmum',
  network: 'amoy',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  rpcUrls: {
    public: {
      http: ['https://rpc-amoy.polygon.technology'],
    },
    default: {
      http: ['https://rpc-amoy.polygon.technology'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'PolygonScan',
      url: 'https://www.oklink.com/amoy',
    },
    default: {
      name: 'PolygonScan',
      url: 'https://www.oklink.com/amoy',
    },
  },
  testnet: true,
};
export const defaultChains = [bsc, bscTestnet, sepolia, mainnet, polygon, amoy];
