import { assertArgument } from '@haechi-labs/face-types';

import { Internal } from '../internal';
import { HashConnect } from './HashConnect';

export class Hedera {
  internal: Internal;
  hashConnect: HashConnect;

  constructor(internal: Internal) {
    this.internal = internal;
    this.hashConnect = new HashConnect(internal);
  }

  getHashConnect() {
    return this.hashConnect;
  }

  async hederaAssociate(tokenId: string) {
    assertArgument(tokenId, typeof tokenId === 'string', 'tokenId');

    return await this.internal.hederaAssociate(tokenId);
  }

  async getAccountId() {
    return await this.internal.getHederaAccountId();
  }
}
