import { Network } from '@haechi-labs/face-types';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import nacl from 'tweetnacl';

import { signMessage } from '../../libs/aptosCall';
import { accountAtom, faceAtom, networkAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Field from '../common/Field';
import Message from '../common/Message';

const title = 'Aptos SignMessage';

function AptosSignMessage() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const network = useRecoilValue(networkAtom);
  const [message, setMessage] = useState('');
  const [nonce, setNonce] = useState('');
  const [signedMessage, setSignedMessage] = useState('');

  async function aptosSignMessage() {
    try {
      if (!message) {
        alert('Please set message');
        return;
      }

      const aptosProvider = face!.aptos.getProvider();
      const address = (await aptosProvider.getAddresses())[0];
      const publicKey = (await aptosProvider.getPublicKeys())[0];

      const signedMsg = await signMessage({
        face: face!,
        message,
        nonce,
      });

      // Remove the 0x prefix
      const verified = nacl.sign.detached.verify(
        Buffer.from(signedMsg.fullMessage),
        Buffer.from((signedMsg.signature as string).slice(2), 'hex'),
        Buffer.from(publicKey.noPrefix(), 'hex')
      );

      console.group('[Sign Information]');
      console.log('address: ' + address);
      console.log('Signed message:', signedMsg);
      console.log('Verified:', verified);
      console.groupEnd();
      setSignedMessage(JSON.stringify(signedMsg));
    } catch (error) {
      console.error(error);
    }
  }

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  } else {
    if (Network.APTOS_TESTNET != network) {
      return (
        <Box title={title}>
          <Message type="danger">
            To test the Aptos Call, you must connect to the Aptos Testnet.
          </Message>
        </Box>
      );
    }
  }
  if (!account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  return (
    <Box title={title}>
      <Field label="Message">
        <input className="input" value={message} onChange={(e) => setMessage(e.target.value)} />
      </Field>
      <Field label="Nonce">
        <input className="input" value={nonce} onChange={(e) => setNonce(e.target.value)} />
      </Field>
      <Button onClick={aptosSignMessage}>Sign Message</Button>
      {signedMessage && (
        <Message type="info" className="has-text-left">
          <h4 className="has-text-weight-bold">Signed message</h4>
          <div>{signedMessage}</div>
          <br />
        </Message>
      )}
    </Box>
  );
}

export default AptosSignMessage;
