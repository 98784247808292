import { isEthlikeBlockchain, networkToBlockchain } from '@haechi-labs/shared';
import { ethers, providers } from 'ethers';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { filterTypes } from '../../libs/utils';
import { accountAtom, faceAtom, networkAtom, walletAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Field from '../common/Field';
import Message from '../common/Message';

const title = 'Sign EIP712 Message';
const defaultChainId = 1; // ethereum
const generateDefaultMessage = (chainId = 1) =>
  `{"types":{"Order":[{"name":"isSeller","type":"uint8"},{"name":"maker","type":"address"},{"name":"listingTime","type":"uint64"},{"name":"expirationTime","type":"uint64"},{"name":"tokenAddress","type":"address"},{"name":"tokenAmount","type":"uint256"},{"name":"nftAddress","type":"address"},{"name":"nftId","type":"uint256"},{"name":"nftAmount","type":"uint256"},{"name":"salt","type":"uint256"},{"name":"mail","type":"Mail"}],"Mail":[{"name":"from","type":"address"},{"name":"to","type":"address"},{"name":"contents","type":"string"},{"name":"ref","type":"address[]"}]},"primaryType":"Order","domain":{"name":"Marketplace","version":"1.0","chainId":${chainId},"verifyingContract":"0x18F7E95323b188C82b582cdE2F6606e0198CCC09"},"message":{"isSeller":0,"maker":"0x5C6213EC52c34B052696e36ed2D4B343DF5dAD1C","listingTime":1681262000,"expirationTime":1681348400,"tokenAddress":"0x3aa7d08b3042C775AE3718335E2d691a223f9F3a","tokenAmount":190,"nftAddress":"0xDaE15C34154cf9FBef6Ed4723481ae44EB63CdBE","nftId":"7913031215882140490192549673218","nftAmount":0,"salt":0,"mail":{"from":"0xD444D35e329f49889b78182DCdDC556Ec29cE714","to":"0xD444D35e329f49889b78182DCdDC556Ec29cE714","contents":"Hello, World!","ref":["0xD444D35e329f49889b78182DCdDC556Ec29cE714","0xD444D35e329f49889b78182DCdDC556Ec29cE714","0xD444D35e329f49889b78182DCdDC556Ec29cE714"]}}}`;

function SignEip712Message() {
  const face = useRecoilValue(faceAtom);
  const network = useRecoilValue(networkAtom);
  const [chainId, setChainId] = useState<number>(defaultChainId);
  const [message, setMessage] = useState(generateDefaultMessage(1));

  useEffect(() => {
    setChainIdByNetwork();

    async function setChainIdByNetwork() {
      if (!face) return;
      const chainId = await face.getChainId();
      setChainId(chainId);
      setMessage(generateDefaultMessage(chainId));
    }
  }, [face, network]);

  const account = useRecoilValue(accountAtom);
  const wallet = useRecoilValue(walletAtom);
  const [signedMessage, setSignedMessage] = useState('');
  const [recoveredAddress, setRecoveredAddress] = useState('');
  const [verificationFail, setVerificationFail] = useState(false);

  if (network && !isEthlikeBlockchain(networkToBlockchain(network))) return null;

  const signEip712Message = async () => {
    try {
      const provider = new providers.Web3Provider(
        wallet ? await wallet.connector.getProvider() : face!.getEthLikeProvider(),
        'any'
      );
      const signer = provider.getSigner();
      const typedData = JSON.parse(message);

      const address = await signer.getAddress();
      const signature = await provider.send('eth_signTypedData_v4', [
        address.toLowerCase(),
        message,
      ]);
      const recoverAddress = ethers.utils.verifyTypedData(
        typedData.domain,
        filterTypes(typedData.types, typedData.primaryType),
        typedData.message,
        signature
      );
      setSignedMessage(signature);
      setRecoveredAddress(recoverAddress);
      if (recoverAddress === address) {
        setVerificationFail(false);
      } else {
        setVerificationFail(true);
      }
    } catch (error) {
      alert('Error: Signing typed data failed.');
      console.error('Signing typed data failed:', error);
    }
  };

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }
  if (!account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  return (
    <Box title={title}>
      <div>
        current network: {network}(chainId: {chainId})
      </div>
      <Field label="Message">
        <textarea
          className="textarea"
          value={message}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
      </Field>
      <Button onClick={() => signEip712Message()}>Sign EIP 712 Message</Button>

      {!verificationFail && signedMessage && (
        <Message type="info" className="has-text-left">
          <h4 className="has-text-weight-bold">Signed message</h4>
          <div>{signedMessage}</div>
          <br />
          <h4 className="has-text-weight-bold">Verification result</h4>
          <div>recovered address: {recoveredAddress}</div>
        </Message>
      )}
      {verificationFail && (
        <Message type="danger" className="has-text-left">
          <h4 className="has-text-weight-bold">Signed message</h4>
          <div>{signedMessage}</div>
          <br />
          <h4 className="has-text-weight-bold">Verification result</h4>
          <div>recovered address: {recoveredAddress}</div>
          <div>address: {account.address}</div>
        </Message>
      )}
    </Box>
  );
}

export default SignEip712Message;
