import { Face } from '@haechi-labs/face-sdk';
import { AptosSignMessageResponse, Network } from '@haechi-labs/face-types';
import { networkToBlockchain } from '@haechi-labs/shared';
import {
  AptosAccount,
  AptosClient,
  BCS,
  HexString,
  TransactionBuilder,
  TxnBuilderTypes,
  Types,
} from 'aptos';

import { createPlatformCoin, SignedTransaction } from './types';
import { getProvider } from './utils';

export async function signMultiAgentTransaction({
  face,
  amount,
  network,
  receiverAddress,
}: {
  face: Face;
  amount: string;
  network: Network;
  receiverAddress: string;
}): Promise<SignedTransaction> {
  const aptosProvider = face.aptos.getProvider();
  const senderAddress = (await aptosProvider.getAddresses())[0];
  const senderPublicKey = (await aptosProvider.getPublicKeys())[0];

  const aptosClient = new AptosClient(getProvider(network));

  const payerAccount = new AptosAccount(
    HexString.ensure(
      '0x0e10dbe64011adbb4f25dc0a96b7532a10ed5267a4f1ac47d92997fa8335a3ec57b46254eedc4fce5bde2bdce0589d88f84afc4485b84a0469167f7274b3d2ea'
    ).toUint8Array()
  );

  const payerAccountData = await aptosClient.getAccount(payerAccount.address());
  const sequenceNumber = parseInt(payerAccountData.sequence_number);

  const rawTransaction = await aptosClient.generateTransaction(
    payerAccount.address(),
    {
      arguments: [
        receiverAddress,
        createPlatformCoin(amount, networkToBlockchain(network)).toDecimalAmountAsString(),
      ],
      function:
        '0xad4ba870dd4d8b2c2b6b9688f56c71509b54ab5cf3c0683be15e99f750b2ccc2::coin::transfer_p',
      type_arguments: ['0x1::aptos_coin::AptosCoin'],
    },
    {
      sequence_number: sequenceNumber.toString(),
    }
  );
  const multiAgentRawTx = new TxnBuilderTypes.MultiAgentRawTransaction(rawTransaction, [
    TxnBuilderTypes.AccountAddress.fromHex(senderAddress),
  ]);

  const signingMessage = TransactionBuilder.getSigningMessage(multiAgentRawTx);

  // Face SDK Sign MultiAgent Transaction
  const senderSignature = await aptosProvider.signMultiAgentTransaction(multiAgentRawTx);
  const payerSignature = payerAccount.signBuffer(signingMessage);

  const payerAuthenticator = new TxnBuilderTypes.AccountAuthenticatorEd25519(
    new TxnBuilderTypes.Ed25519PublicKey(payerAccount.pubKey().toUint8Array()),
    new TxnBuilderTypes.Ed25519Signature(payerSignature.toUint8Array())
  );

  const senderAuthenticator = new TxnBuilderTypes.AccountAuthenticatorEd25519(
    new TxnBuilderTypes.Ed25519PublicKey(HexString.ensure(senderPublicKey).toUint8Array()),
    new TxnBuilderTypes.Ed25519Signature(HexString.ensure(senderSignature).toUint8Array())
  );

  const multiAuthenticator = new TxnBuilderTypes.TransactionAuthenticatorMultiAgent(
    payerAuthenticator,
    [TxnBuilderTypes.AccountAddress.fromHex(senderAddress)],
    [senderAuthenticator]
  );

  const signedTx = new TxnBuilderTypes.SignedTransaction(
    multiAgentRawTx.raw_txn,
    multiAuthenticator
  );

  const pendingTransaction = await aptosClient.submitTransaction(BCS.bcsToBytes(signedTx));
  await aptosClient.waitForTransaction(pendingTransaction.hash);

  console.group('Sign MultiAgent Transaction');
  console.log(pendingTransaction.hash);
  console.groupEnd();

  return {
    hash: pendingTransaction.hash,
  };
}

export async function signAndSubmitTransaction({
  face,
  amount,
  network,
  receiverAddress,
}: {
  face: Face;
  amount: string;
  network: Network;
  receiverAddress: string;
}): Promise<SignedTransaction> {
  const aptosProvider = face.aptos.getProvider();
  const transaction = {
    arguments: [
      receiverAddress,
      createPlatformCoin(amount, networkToBlockchain(network)).toDecimalAmountAsString(),
    ],
    function: '0x1::coin::transfer',
    type: 'entry_function_payload',
    type_arguments: ['0x1::aptos_coin::AptosCoin'],
  };

  const { hash } = await aptosProvider.signAndSubmitTransaction(transaction);

  console.group('Sign and Submit Transaction');
  console.log(hash);
  console.groupEnd();

  return {
    hash,
  };
}

export async function signTransaction({
  face,
  amount,
  network,
  receiverAddress,
}: {
  face: Face;
  amount: string;
  network: Network;
  receiverAddress: string;
}): Promise<SignedTransaction> {
  const aptosProvider = face.aptos.getProvider();

  const transaction = {
    arguments: [
      receiverAddress,
      createPlatformCoin(amount, networkToBlockchain(network)).toDecimalAmountAsString(),
    ],
    function: '0x1::coin::transfer',
    type: 'entry_function_payload',
    type_arguments: ['0x1::aptos_coin::AptosCoin'],
  };

  const submitTransactionRequest = await aptosProvider.signTransaction(transaction);
  const aptosClient = new AptosClient(getProvider(network));

  const pendingTransaction = await aptosClient.submitTransaction(
    Buffer.from(
      (
        (submitTransactionRequest.signature as Types.AccountSignature_Ed25519Signature)
          .signature as string
      ).slice(2),
      'hex'
    )
  );
  await aptosClient.waitForTransaction(pendingTransaction.hash);

  console.group('Sign Transaction');
  console.log('SubmitTransactionRequest: ', submitTransactionRequest);
  console.groupEnd();

  return { hash: pendingTransaction.hash };
}

export async function signMessage({
  face,
  message,
  nonce,
}: {
  face: Face;
  message: string;
  nonce: string;
}): Promise<AptosSignMessageResponse> {
  const aptosProvider = face.aptos.getProvider();
  const res = await aptosProvider.signMessage({ message, nonce });

  console.group('Aptos Sign Message');
  console.log(res);
  console.groupEnd();

  return res;
}
