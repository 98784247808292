import {
  Blockchain,
  FaceSolanaNearTransactionPayload,
  JsonRpcMethod,
} from '@haechi-labs/face-types';
import { PublicKey, Transaction } from '@solana/web3.js';
import { ethers } from 'ethers';

import { Internal } from '../internal';

export class SolanaProvider {
  internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  async getPublicKeys(): Promise<PublicKey[]> {
    const addresses = await this.internal.getAddresses(Blockchain.SOLANA);
    return addresses.map((address) => {
      return new PublicKey(ethers.utils.base58.decode(address));
    });
  }

  async signAndSendTransaction(transaction: Transaction): Promise<string> {
    // TODO: transaction 유효성 검사

    const txPayload: FaceSolanaNearTransactionPayload = {
      serializedHex: transaction
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false,
        })
        .toString('hex'),
    };
    const result = await this.internal.sendRpc<any>({
      method: JsonRpcMethod.solana_sendTransaction,
      params: [txPayload],
    });

    return result!;
  }
}
