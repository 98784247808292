import { Blockchain, Env, FaceError, FaceErrorCode, Network } from '@haechi-labs/face-types';
import {
  getChainId,
  getNetworkByChainId,
  IFRAME_URL,
  isEthlikeNetwork,
  isMainnet,
  networkToBlockchain,
} from '@haechi-labs/shared';
import { ethers } from 'ethers';

export function getBlockchainFromNetwork(network?: Network): Blockchain {
  if (network) {
    return networkToBlockchain(network);
  } else {
    return Blockchain.ETHEREUM;
  }
}

export function getIframeUrl(env?: Env, iframeUrl?: string): string {
  if (iframeUrl != null) {
    return iframeUrl;
  }
  switch (env) {
    case Env.Local:
      return IFRAME_URL.Local;
    case Env.Dev:
      return IFRAME_URL.Dev;
    case Env.StageTest:
      return IFRAME_URL.StageTestnet;
    case Env.ProdTest:
      return IFRAME_URL.ProdTestnet;
    case Env.StageMainnet:
      return IFRAME_URL.StageMainnet;
    case Env.ProdMainnet:
      return IFRAME_URL.ProdMainnet;
    default:
      return IFRAME_URL.ProdMainnet;
  }
}

export function getNetwork(network: Network | number | string): Network {
  const hexRegex = /^0x[0-9A-Fa-f]*$/;
  if (typeof network === 'string' && hexRegex.test(network)) {
    const chainId = parseInt(network, 16);

    try {
      return getNetworkByChainId(chainId);
    } catch (err) {
      throw new FaceError({
        message: `Invalid chainId: ${chainId}`,
        code: FaceErrorCode.UNSUPPORTED_CHAIN,
      });
    }
  }

  if (typeof network === 'number') {
    try {
      return getNetworkByChainId(network);
    } catch (err) {
      throw new FaceError({
        message: `Invalid chainId: ${network}`,
        code: FaceErrorCode.UNSUPPORTED_CHAIN,
      });
    }
  }

  if (Object.values(Network).includes(network as Network)) {
    return network as Network;
  }

  throw new FaceError({
    message: `Invalid network parameter: ${network}`,
    code: FaceErrorCode.UNSUPPORTED_CHAIN,
  });
}

export const getEthlikeChainIdFromNetwork = (network: Network) => {
  return isEthlikeNetwork(network) ? ethers.utils.hexlify(getChainId(network)) : 0;
};

export function isEveryNetworkOnSameEnv(networks: Network[]) {
  return (
    networks.every((network) => isMainnet(network)) ||
    networks.every((network) => !isMainnet(network))
  );
}
