import { Env } from '@haechi-labs/face-types';

// 로컬의 경우 로컬 디비에 자동 생성된 dapp이 이 apiKey를 사용함
export const API_KEY =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCS23ncDS7x8nmTuK1FFN0EfYo0vo6xhTBMBNWVbQsufv60X8hv3-TbAQ3JIyMEhLo-c-31oYrvrQ0G2e9j8yvJYEUnLuE-PaABo0y3V5m9g_qdTB5p9eEfqZlDrcUl1zUr4W7rJwFwkTlAFSKOqVCPnm8ozmcMyyrEHgl2AbehrQIDAQAB';

export const resolveApiKey = (env: Env | null) => {
  switch (env) {
    case Env.StageMainnet:
      return 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCAzwMteOlPhbHkRMA1dJo0D3yCtKXWR9mLmTZmNwnEw6O9nVxb88G8wqMZSRapuhKxT-PRDf4yuDLJgSeHHB8k6l6jlhTjDmA9y6JB052lrneOf0z4NmbQJ3BsiyAHX7vrPa-wDMd6lcmGNKW-BowwbBDLi6yy59qlPBex8Bba_wIDAQAB';
    case Env.ProdMainnet:
      return 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDFhGcETETTZVrysoeadS_HGjY3p_jxFkZMR3aE2Sy-d7kjn_WJLYd5f3voEK9mbmLvglt3SGLw08xh_mVFD3rP8QoCNmjkyCbMIeszpCtudAAq1Ar37ZWCKQArWlhXB9EtTU9e3E4FBcBW9vqaPxiT8sk2MBFkCeinNSDHTdQ2uwIDAQAB';
    case Env.Dev:
    case Env.Local:
    case Env.StageTest:
    case Env.ProdTest:
      return API_KEY;
    default:
      throw new Error('unsupported network error');
  }
};
