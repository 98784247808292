import { networkToBlockchain } from '@haechi-labs/shared';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { sendTransaction } from '../../libs/transaction';
import { createPlatformCoin } from '../../libs/types';
import { getExplorerUrl } from '../../libs/utils';
import { accountAtom, faceAtom, networkAtom, walletAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Field from '../common/Field';
import Message from '../common/Message';

const title = 'Platform Coin Transaction';

function TransactionPlatformCoin() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const network = useRecoilValue(networkAtom)!;
  const wallet = useRecoilValue(walletAtom);
  const [txHash, setTxHash] = useState('');
  const [amount, setAmount] = useState('0.001');
  const [receiverAddress, setReceiverAddress] = useState('');

  async function sendTransactionCallback() {
    try {
      const sentTx = await sendTransaction({
        face: face!,
        receiverAddress,
        amount: createPlatformCoin(amount, networkToBlockchain(network)),
        network,
        wallet,
      });

      setTxHash(sentTx.hash);

      console.group('[Transaction Information]');
      console.log('Transaction response:', sentTx);
      console.log('Explorer Link:', `${getExplorerUrl(network, sentTx.hash)}`);

      const receipt = await sentTx.wait();
      console.log('Transaction receipt', receipt);
      console.groupEnd();
    } catch (e) {
      if (e.isFaceError && e.code === 4001) {
        console.log('User rejected!');
        return;
      }
      throw e;
    }
  }

  useEffect(() => {
    // Set receiver to user account
    if (account.address) {
      setReceiverAddress(account.address);
    }
  }, [account.address]);

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }
  if (!account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  return (
    <Box title={title}>
      <Field label="Amount">
        <input
          className="input"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
        />
      </Field>
      <Field label="Receiver Address">
        <input
          className="input"
          value={receiverAddress}
          onChange={(e) => setReceiverAddress(e.target.value)}
        />
      </Field>
      {/* TODO: form에서 받은 amount 표기*/}
      <Button onClick={sendTransactionCallback}>Transfer 0.001 coin</Button>
      {txHash && (
        <>
          <Message type="info">Hash: {txHash}</Message>
          <Message type="info">
            <a
              href={`${getExplorerUrl(network, txHash)}`}
              rel="noopener noreferrer"
              target="_blank">
              Explorer Link
            </a>
          </Message>
        </>
      )}
    </Box>
  );
}

export default TransactionPlatformCoin;
