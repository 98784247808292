import {
  AptosSignMessagePayload,
  AptosSignMessageResponse,
  AptosTxOptions,
  Blockchain,
  JsonRpcMethod,
  Network,
} from '@haechi-labs/face-types';
import { BCS, HexString, TxnBuilderTypes, Types } from 'aptos';

import { Internal } from '../internal';

export class AptosProvider {
  internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  async getPublicKeys(): Promise<HexString[]> {
    throw new Error('Not implemented yet');
    // @Roy: 기존에 Aptos 퍼블릭 키를 추출하는 로직에 문제가 있었습니다.
    // @Roy: Tezos 의 경우처럼, public key 를 가져오는 JsonRpcMethod 핸들링이 필요합니다.
    // 관련 슬랙 링크 : https://haechilabs.slack.com/archives/C03RPALDC3S/p1703131631955459?thread_ts=1702966323.284549&cid=C03RPALDC3S
    // const addresses = await this.internal.getAddresses(Blockchain.APTOS);
    // return addresses.map((address) => {
    //   return HexString.ensure(address);
    // });
  }

  async getAddresses(): Promise<string[]> {
    const addresses = await this.internal.getAddresses(Blockchain.APTOS);
    return addresses;
  }

  async signMultiAgentTransaction(rawTransaction: TxnBuilderTypes.MultiAgentRawTransaction) {
    // TODO: rawTransaction 유효성 검사
    const result = await this.internal.sendRpc<string>({
      method: JsonRpcMethod.aptos_signMultiAgentTransaction,
      params: [rawTransaction.raw_txn.sender.address, BCS.bcsToBytes(rawTransaction)],
    });

    return result!;
  }

  async signMessage(message: AptosSignMessagePayload): Promise<AptosSignMessageResponse> {
    const serializeMessage = Buffer.from(JSON.stringify(message));
    const serializedRes = await this.internal.sendRpc<string>({
      method: JsonRpcMethod.aptos_signMessage,
      params: [serializeMessage],
    });

    return JSON.parse(serializedRes!);
  }

  async signAndSubmitTransaction(
    transaction: Types.TransactionPayload_EntryFunctionPayload,
    options?: AptosTxOptions
  ): Promise<{
    hash: Types.HexEncodedBytes;
  }> {
    const senderAddress = (await this.internal.getAddresses(Blockchain.APTOS))[0];
    const result = await this.internal.sendRpc<string>({
      method: JsonRpcMethod.aptos_signAndSubmitTransaction,
      params: [Buffer.from(senderAddress), Buffer.from(JSON.stringify(transaction)), options],
    });

    return {
      hash: result!,
    };
  }

  async signTransaction(
    transaction: Types.TransactionPayload_EntryFunctionPayload,
    options?: AptosTxOptions
  ): Promise<Types.SubmitTransactionRequest> {
    const senderAddress = (await this.internal.getAddresses(Blockchain.APTOS))[0];
    const result = await this.internal.sendRpc<string>({
      method: JsonRpcMethod.aptos_signTransaction,
      params: [Buffer.from(senderAddress), Buffer.from(JSON.stringify(transaction)), options],
    });

    return JSON.parse(result!);
  }
}
