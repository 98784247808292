import { JsonRpcMethod, JsonRpcResponsePayload } from '@haechi-labs/face-types';

import eventEmitter from './events';
import { Internal } from './internal';

export interface RequestArguments {
  method: string;
  params?: any;

  [key: string]: any;
}

class ProviderTemplate {
  sendAsync = (r: any, c: (e: any, r: any) => void) => void 0;
}

export class Provider extends ProviderTemplate {
  private readonly internal: Internal;

  constructor(internal: Internal) {
    super();
    this.internal = internal;
  }

  async request(
    request: RequestArguments | RequestArguments[]
  ): Promise<JsonRpcResponsePayload['result']> {
    if (Array.isArray(request)) {
      return Promise.all(request.map((req) => this.internal.sendRpc(req)));
    }

    switch (request.method) {
      case JsonRpcMethod.wallet_switchEthereumChain:
        return this.internal.switchNetwork(request.params[0].chainId);
      case JsonRpcMethod.eth_estimateGas:
        return this.internal.estimateGas();
      case 'personal_listAccounts':
      case 'eth_accounts':
        return this.internal.getAddresses();
      default:
        return this.internal.sendRpc(request);
    }
  }

  on(eventName: string | symbol, listener: (...args: any[]) => void): this {
    eventEmitter.on(eventName, listener);
    return this;
  }

  emit(eventName: string, ...args: any[]): boolean {
    return eventEmitter.emit(eventName, ...args);
  }

  removeListener(eventName: string | symbol, listener: (...args: any[]) => void): this {
    eventEmitter.removeListener(eventName, listener);
    return this;
  }

  off(event: string | symbol, listener: (...args: any[]) => void): this {
    eventEmitter.off(event, listener);
    return this;
  }
}
