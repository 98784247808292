import './App.css';

import { ReactComponent as Logo } from './assets/logo.svg';
import AccountInformation from './components/AccountInformation';
import AptosCall from './components/boxs/AptosCall';
import AptosContractCall from './components/boxs/AptosContractCall';
import AptosSignMessage from './components/boxs/AptosSignMessage';
import BoraPortal from './components/boxs/BoraPortal';
import Buy from './components/boxs/Buy';
import ConnectNetwork from './components/boxs/ConnectNetwork';
import Etc from './components/boxs/Etc';
import Faucet from './components/boxs/Faucet';
import Hedera from './components/boxs/Hedera';
import LoginWithFace from './components/boxs/LoginWithFace';
import Operation from './components/boxs/Operation';
import SignEip712Message from './components/boxs/SignEip712Message';
import SignMessage from './components/boxs/SignMessage';
import TezosFa2Ft from './components/boxs/TezosFa2Ft';
import TezosFa2Mft from './components/boxs/TezosFa2Mft';
import TransactionContractCall from './components/boxs/TransactionContractCall';
import TransactionErc20 from './components/boxs/TransactionErc20';
import TransactionErc721 from './components/boxs/TransactionErc721';
import TransactionErc1155 from './components/boxs/TransactionErc1155';
import TransactionPlatformCoin from './components/boxs/TransactionPlatformCoin';
import UserVerification from './components/boxs/UserVerification';
import WalletConnect from './components/boxs/WalletConnect';
import WalletHome from './components/boxs/WalletHome';

function App() {
  return (
    <div className="App">
      <Logo height={40} width={320} />
      <AccountInformation />
      <div className="wrapper">
        <ConnectNetwork />
        <LoginWithFace />
        <WalletHome />
        <Buy />
        <Faucet />
        <TransactionPlatformCoin />
        <TransactionErc20 />
        <TransactionErc721 />
        <TransactionErc1155 />
        <TransactionContractCall />
        <SignMessage />
        <SignEip712Message />
        <WalletConnect />
        <TezosFa2Ft />
        <TezosFa2Mft />
        <AptosCall />
        <AptosSignMessage />
        <AptosContractCall />
        <BoraPortal />
        <Operation />
        <UserVerification />
        <Etc />
        <Hedera />
      </div>
    </div>
  );
}

export default App;
