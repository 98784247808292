import { Face, Network } from '@haechi-labs/face-sdk';
import { Env } from '@haechi-labs/face-types';
import { IFRAME_URL } from '@haechi-labs/shared';
import { getNetworkByChainId, isEthlikeNetwork, networkToBlockchain } from '@haechi-labs/shared';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { resolveApiKey } from '../../config/apiKey';
import { resolvePrvKey } from '../../config/prvKey';
import { envAtom, faceAtom, networkAtom } from '../../store';
import { privateKeyAtom } from '../../store/privateKeyAtom';
import Box from '../common/Box';
import Button from '../common/Button';
import Checkbox from '../common/Checkbox';
import Field from '../common/Field';
import Message from '../common/Message';
import Select from '../common/Select';

function getIframeUrl(env: Env) {
  switch (env) {
    case Env.Local:
      return IFRAME_URL.Local;
    case Env.Dev:
      return IFRAME_URL.Dev;
    case Env.StageTest:
      if (isMultiStageDomain(window.location.hostname)) {
        return multiStageIframeDomain(window.location.hostname);
      }
      return IFRAME_URL.StageTestnet;
    case Env.StageMainnet:
      return IFRAME_URL.StageMainnet;
    case Env.ProdTest:
      return IFRAME_URL.ProdTestnet;
    case Env.ProdMainnet:
      return IFRAME_URL.ProdMainnet;
    default:
      throw new Error('Invalid env');
  }
}

function isMultiStageDomain(hostname: string) {
  return hostname.match(/.*\.facewallet-test\.xyz$/);
}

function multiStageIframeDomain(hostname: string) {
  return 'https://' + hostname.replace('face-sample-dapp', 'face-iframe');
}

export function getEnv() {
  const hostname = window.location.hostname;

  const devUrls = ['sample-dapp.dev.facewallet.xyz'];
  const stageUrls = ['sample-dapp.stage-test.facewallet.xyz', 'sample-dapp.stage.facewallet.xyz'];
  const prodUrls = ['sample-dapp.test.facewallet.xyz', 'sample-dapp.facewallet.xyz'];

  const isDev = devUrls.includes(hostname);
  const isStage = stageUrls.includes(hostname) || isMultiStageDomain(hostname);
  const isProd = prodUrls.includes(hostname);

  if (isDev) return Env.Dev;
  if (isStage) return Env.StageTest;
  if (isProd) return Env.ProdMainnet;
  return Env.Local;
}

function ConnectNetwork() {
  const [face, setFace] = useRecoilState(faceAtom);
  const [network, setNetwork] = useRecoilState(networkAtom);
  const [env, setEnv] = useRecoilState(envAtom);
  const [iframeUrl, setIframeUrl] = useState<string>(getIframeUrl(getEnv()));
  const [apiKey, setApiKey] = useState<string>(resolveApiKey(getEnv()));
  const [isCustomNotification, setIsCustomNotification] = useState(false);
  const [prvKey, setPrvKey] = useRecoilState(privateKeyAtom);
  const [chainId, setChainId] = useState('');
  const [chainIdHex, setChainIdHex] = useState('');

  const envList = Object.values(Env);
  const iframeUrlList = envList.map((env) => getIframeUrl(env));
  const isFaceInitialized = !!face;

  useEffect(() => {
    setEnv(getEnv());
  }, [network, setEnv]);

  useEffect(() => {
    const listener = (e: any) => {
      console.log('[Notification] title', e?.detail?.title);
      console.log('[Notification] description', e?.detail?.description);
      console.log('[Notification] type', e?.detail?.type);
    };
    window.addEventListener('face-toast', listener);

    return () => {
      window.removeEventListener('face-toast', listener);
    };
  }, []);

  const connectNetworkTo = (network: Network) => {
    setNetwork(network);

    try {
      if (isFaceInitialized) {
        face.switchNetwork(network as Network);
      } else {
        const face = new Face({
          apiKey: apiKey,
          network,
          env,
          iframeUrl,
          notificationOptions: {
            type: isCustomNotification ? 'none' : 'toast',
          },
        } as never);
        if (isEthlikeNetwork(face?.getNetwork())) {
          face!.getEthLikeProvider().on('chainChanged', (cid) => {
            console.log(`Chain changed to: ${cid}`);
          });
        }
        setFace(face);
      }
    } catch (e) {
      alert('Error occurred');
      console.error(e);
    }
  };

  const connectWithChainId = () => {
    const _chainId = Number(chainId);
    setNetwork(getNetworkByChainId(_chainId));
    try {
      if (isFaceInitialized) {
        face.switchNetwork(_chainId);
      } else {
        const face = new Face({
          apiKey: apiKey,
          network: _chainId,
          env,
          iframeUrl,
          notificationMode: isCustomNotification,
        } as never);
        if (isEthlikeNetwork(face?.getNetwork())) {
          face!.getEthLikeProvider().on('chainChanged', (cid) => {
            console.log(`Chain changed to: ${cid}`);
          });
        }
        setFace(face);
      }
    } catch (e) {
      alert('Error occurred');
      console.error(e);
    }
  };

  const connectWithChainIdHexString = () => {
    const _chainIdHex = String(chainIdHex);
    const hexRegex = /^0x[0-9A-Fa-f]*$/;
    try {
      if (!hexRegex.test(_chainIdHex)) {
        throw new Error(`Is not hex string chainId: ${_chainIdHex}`);
      }
      const chainId = parseInt(_chainIdHex, 16);

      try {
        setNetwork(getNetworkByChainId(chainId));
      } catch (err) {
        throw new Error(`Invalid chainId: ${chainId}`);
      }

      if (isFaceInitialized) {
        face.switchNetwork(_chainIdHex);
      } else {
        const face = new Face({
          apiKey: apiKey,
          network: _chainIdHex,
          env,
          iframeUrl,
          notificationMode: isCustomNotification,
        } as never);
        if (isEthlikeNetwork(face?.getNetwork())) {
          face!.getEthLikeProvider().on('chainChanged', (cid) => {
            console.log(`Chain changed to: ${cid}`);
          });
        }
        setFace(face);
      }
    } catch (e) {
      alert('Error occurred');
      console.error(e);
    }
  };

  const action = isFaceInitialized ? 'Switch' : 'Connect';

  const networks: { value: Network | ''; name: string }[] = Object.values(Network).map(
    (network) => ({
      value: network,
      name: `${networkToBlockchain(network)}(${network})`,
    })
  );
  networks.unshift({ value: '', name: 'Select Network' });

  return (
    <Box title={'Connect Network (Init SDK)'}>
      {/* apiKey, network, notificationMode만 Face Config 에서 사용되는 값. env, iframeUrl 등은 내부 개발용도로만 사용함 */}

      {isFaceInitialized && (
        <Message type="info">
          <div className="has-text-weight-bold">Connected to {network}</div>
          <div>Env: {env}</div>
          <div>Iframe Url: {iframeUrl}</div>
        </Message>
      )}
      <hr />

      <Field label="apiKey">
        <input
          name="api-key"
          className="input"
          type="text"
          onChange={(e) => setApiKey(e.target.value)}
          value={apiKey}
          disabled={isFaceInitialized}
        />
      </Field>

      <Field label="apiSecret">
        <input
          name="api-secret"
          className="input"
          type="text"
          onChange={(e) => setPrvKey(e.target.value)}
          value={prvKey}
          disabled={isFaceInitialized}
        />
      </Field>

      <Field label={`${action} to`}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
          <Select
            items={networks}
            onSelect={(value) => {
              if (value === '') {
                setNetwork(null);
                setFace(null);
                document.getElementById('face-iframe')?.remove();
                return;
              }
              connectNetworkTo(value as Network);
            }}
          />
        </div>
      </Field>

      <Field label="Chain ID">
        <input
          name="chain-id"
          className="input"
          type="number"
          onChange={(e) => setChainId(e.target.value)}
          value={chainId}
        />
      </Field>
      <Button onClick={() => connectWithChainId()}>{action} with Chain ID</Button>

      <Field label="Chain ID Hex">
        <input
          name="chain-id-hex"
          className="input"
          type="string"
          onChange={(e) => setChainIdHex(e.target.value)}
          value={chainIdHex}
        />
      </Field>
      <Button
        onClick={() => {
          connectWithChainIdHexString();
        }}>
        {action} with Chain ID
      </Button>

      <Field label="notificationMode">
        <div style={{ display: 'flex', justifyContent: 'start' }}>
          <Checkbox
            id="notificationMode"
            defaultChecked={false}
            item={'true'}
            onCheck={(checked) => setIsCustomNotification(checked)}
          />
        </div>
      </Field>
      <hr />

      <div>개발용</div>
      <Field label="Env">
        <Select
          items={envList}
          onSelect={(value) => {
            setEnv(value as Env);
            setApiKey(resolveApiKey(value as Env));
            setPrvKey(resolvePrvKey(value as Env));
          }}
          value={env}
          disabled={isFaceInitialized}
        />
      </Field>

      <Field label="Iframe">
        <Select
          items={iframeUrlList}
          onSelect={(value) => setIframeUrl(value)}
          value={iframeUrl}
          disabled={isFaceInitialized}
        />
      </Field>
      <hr />
    </Box>
  );
}

export default ConnectNetwork;
