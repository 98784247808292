import { AptosProvider, Face, NearProvider, SolanaProvider } from '@haechi-labs/face-sdk';
import { Blockchain, ConnectedWallet, Network } from '@haechi-labs/face-types';
import { isEthlikeBlockchain, networkToBlockchain } from '@haechi-labs/shared';
import * as solanaWeb3 from '@solana/web3.js';
import { ethers, providers } from 'ethers';
import { poll } from 'ethers/lib/utils';
import * as nearAPI from 'near-api-js';

import { config as nearConfig } from '../config/near';
import { Coin, SentTransaction } from './types';
import { getProvider } from './utils';

type Web3Provider = providers.Web3Provider;

export async function sendTransaction({
  face,
  receiverAddress,
  amount,
  network,
  wallet,
}: {
  face: Face;
  receiverAddress: string;
  amount: Coin;
  network: Network;
  wallet?: ConnectedWallet | null;
}): Promise<SentTransaction> {
  const blockchain = networkToBlockchain(network);
  if (isEthlikeBlockchain(blockchain)) {
    const provider = new providers.Web3Provider(
      wallet ? await wallet.connector.getProvider() : face!.getEthLikeProvider(),
      'any'
    );
    return sendEthereumLikeTransaction({ provider, receiverAddress, amount });
  }
  if (blockchain === Blockchain.SOLANA) {
    const provider = face.solana.getProvider();
    return sendSolanaTransaction({ provider, receiverAddress, amount, network });
  }
  if (blockchain === Blockchain.NEAR) {
    const provider = face.near.getProvider();
    return sendNearTransaction({ provider, receiverAddress, amount, network });
  }
  if (blockchain === Blockchain.APTOS) {
    const provider = face.aptos.getProvider();
    return sendAptosTransaction({ provider, receiverAddress, amount, network });
  }
  if (blockchain === Blockchain.TEZOS) {
    return sendTezosTransaction({ face, receiverAddress, amount });
  }

  throw new Error('invalid blockchain');
}

async function sendEthereumLikeTransaction({
  provider,
  receiverAddress,
  amount,
}: {
  provider: Web3Provider;
  receiverAddress: string;
  amount: Coin;
}): Promise<SentTransaction> {
  const signer = await provider.getSigner();
  const transactionResponse = await signer.sendTransaction({
    to: receiverAddress,
    value: amount.toHexAmount(),
  });

  return {
    hash: transactionResponse.hash,
    wait: async () => {
      const receipt = await transactionResponse.wait();
      return {
        status: receipt.status === 1,
        internal: receipt,
      };
    },
  };
}

async function sendSolanaTransaction({
  provider: solanaProvider,
  receiverAddress,
  amount,
  network,
}: {
  provider: SolanaProvider;
  receiverAddress: string;
  amount: Coin;
  network: Network;
}) {
  const connection = new solanaWeb3.Connection(getProvider(network), 'confirmed');
  const recentBlockhash = await connection.getLatestBlockhash('finalized');
  const publicKeys = await solanaProvider.getPublicKeys();
  const publicKey = publicKeys[0];

  const transaction = new solanaWeb3.Transaction({
    feePayer: publicKey,
    blockhash: recentBlockhash.blockhash,
    lastValidBlockHeight: recentBlockhash.lastValidBlockHeight,
  }).add(
    solanaWeb3.SystemProgram.transfer({
      fromPubkey: publicKey,
      toPubkey: new solanaWeb3.PublicKey(receiverAddress),
      lamports: amount.toDecimalAmountAsNumber(),
    })
  );
  const result = await solanaProvider.signAndSendTransaction(transaction);

  return {
    hash: result,
    wait: async () => {
      await connection.confirmTransaction({
        signature: result,
        ...recentBlockhash,
      });
      const confirmedTx = await connection.getTransaction(result, {
        maxSupportedTransactionVersion: 0,
      });
      console.log('confirmedTx', confirmedTx);
      return {
        status: confirmedTx?.meta?.err == null,
        internal: confirmedTx,
      };
    },
  };
}

async function sendNearTransaction({
  provider: nearProvider,
  receiverAddress,
  amount,
  network,
}: {
  provider: NearProvider;
  receiverAddress: string;
  amount: Coin;
  network: Network;
}) {
  const publicKey = (await nearProvider.getPublicKeys())[0];

  const senderAddress = ethers.utils.hexlify(publicKey.data).slice(2);

  const provider = new nearAPI.providers.JsonRpcProvider({ url: getProvider(network) });
  const accessKey = await provider
    .query<{
      block_height: number;
      block_hash: string;
      nonce: number;
    }>(`access_key/${senderAddress}/${publicKey.toString()}`, '')
    .catch(() => ({ nonce: 0 }));

  const nonce = accessKey.nonce + 1;
  const actions = [nearAPI.transactions.transfer(amount.toDecimalAmountAsString() as any)];

  const near = await nearAPI.connect(nearConfig(network));

  const status = await near.connection.provider.status();

  const blockHash = status.sync_info.latest_block_hash;
  const serializedBlockHash = nearAPI.utils.serialize.base_decode(blockHash);

  const tx = nearAPI.transactions.createTransaction(
    senderAddress,
    publicKey,
    receiverAddress,
    nonce,
    actions,
    serializedBlockHash
  );

  const result = await nearProvider.signAndSendTransaction(tx);

  return {
    hash: result,
    wait: async () => {
      return await poll(async (): Promise<any> => {
        try {
          const receipt = await provider.txStatus(result, senderAddress);
          return {
            status: Object.keys(receipt!.status as any).includes('SuccessValue'),
            internal: receipt,
          };
        } catch (e) {
          console.debug('polling error', e);
          return undefined;
        }
      });
    },
  };
}

async function sendAptosTransaction({
  provider: aptosProvider,
  receiverAddress,
  amount,
}: {
  provider: AptosProvider;
  receiverAddress: string;
  amount: Coin;
  network: Network;
}) {
  const transaction = {
    arguments: [receiverAddress, amount.toDecimalAmountAsString()],
    function: '0x1::coin::transfer',
    type: 'entry_function_payload',
    type_arguments: ['0x1::aptos_coin::AptosCoin'],
  };
  const result = await aptosProvider.signAndSubmitTransaction(transaction);

  return {
    hash: result.hash,
    wait: async () => {
      console.log('no wait function');
      return {
        status: false,
        internal: null,
      };
    },
  };
}

async function sendTezosTransaction({
  face,
  receiverAddress,
  amount,
}: {
  face: Face;
  receiverAddress: string;
  amount: Coin;
}) {
  console.log(`sample-dapp ${receiverAddress}`);
  console.log(`sample-dapp ${amount.toNonDecimalAmountAsString()}`);
  const result = await face.tezos.wallet.transferCoin({
    to: receiverAddress,
    amount: amount.toDecimalAmountAsNumber(),
  });

  return {
    hash: result,
    wait: async () => {
      console.log('no wait function');
      return {
        status: false,
        internal: null,
      };
    },
  };
}
