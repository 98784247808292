import { Network } from '@haechi-labs/face-sdk';
import * as tezosAPI from '@tzkt/sdk-api';
import { tokensGetTokenBalances, tokensGetTokens } from '@tzkt/sdk-api';
import { ethers, utils } from 'ethers';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { getTezosApiUrl } from '../../config/tezos';
import { getExplorerUrl } from '../../libs/utils';
import { accountAtom, faceAtom, networkAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Field from '../common/Field';
import Message from '../common/Message';
import Select from '../common/Select';

export const fa2FtDecimal18ContractAddressMap = {
  [Network.TEZOS]: 'KT1M5P8GZGRehz1WFeDPx8eoipPuyvbdgG3T',
  [Network.GHOSTNET]: 'KT1Wj3aY91Ujsa5Wb6KdRJypZgfXJbo9jrDH',
};

export const fa2FtDecimal6ContractAddressMap = {
  [Network.TEZOS]: 'KT1QgZpGtd5kCgxpcSjkPdN3LrybkpSvCUDe',
  [Network.GHOSTNET]: 'KT1RnoBnLrWFhhrgZbDhc7Y57GsSmsvTw58f',
};

const title = 'FA2(FT) Transaction';

function TezosFa2Ft() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const network = useRecoilValue(networkAtom)!;
  const [txHash, setTxHash] = useState('');
  const [amount, setAmount] = useState('0.001');
  const [contractAddress, setContractAddress] = useState('');
  const [receiverAddress, setReceiverAddress] = useState('');
  const [balance, setBalance] = useState('');
  const [decimal, setDecimal] = useState('18');

  async function sendTransactionCallback() {
    if (!amount) {
      alert('Please enter amount');
      return;
    }
    if (!contractAddress) {
      alert('Please enter contract address');
      return;
    }
    if (!receiverAddress) {
      alert('Please enter receiver address');
      return;
    }
    try {
      if (network != Network.TEZOS && network != Network.GHOSTNET) {
        throw new Error(`FA2 not supported in ${network}`);
      }

      const contract = await face!.tezos.contract.at(contractAddress);
      const address = (await face!.tezos.wallet.getAddresses())[0];
      const tokenId = await getTokenId(contractAddress);
      const hash = await contract.methods.transfer([
        {
          from_: address,
          txs: [
            {
              to_: receiverAddress,
              amount: ethers.utils.parseUnits(amount, decimal).toNumber(),
              token_id: Number(tokenId),
            },
          ],
        },
      ]);

      setTxHash(hash);

      console.group('[Transaction Information]');
      console.log('Transaction response:', hash);
      console.log('Explorer Link:', `${getExplorerUrl(network, hash)}`);
      console.groupEnd();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // Set receiver to user account
    if (account.address) {
      setReceiverAddress(account.address);
    }
  }, [account.address]);

  useEffect(() => {
    if (network != Network.TEZOS && network != Network.GHOSTNET) {
      setContractAddress('NOT SUPPORTED');
      return;
    }
    setContractAddress(
      decimal === '18'
        ? fa2FtDecimal18ContractAddressMap[network]
        : fa2FtDecimal6ContractAddressMap[network]
    );
  }, [decimal, network]);

  async function getBalance() {
    try {
      if (!contractAddress) {
        alert('Please enter contract address');
        return;
      }

      if (network != Network.TEZOS && network != Network.GHOSTNET) {
        throw new Error(`FA2 not supported in ${network}`);
      }
      const address = (await face!.tezos.wallet.getAddresses())[0];
      const tokenId = await getTokenId(contractAddress);
      tezosAPI.defaults.baseUrl = getTezosApiUrl(network);
      const balances = await tokensGetTokenBalances({
        account: {
          eq: address,
        },
        tokenContract: {
          eq: contractAddress,
        },
        tokenTokenId: {
          eq: tokenId,
        },
      });

      if (balances.length > 1) {
        throw new Error('Invalid tezos balance');
      }

      setBalance(utils.formatUnits(balances.length == 0 ? 0 : balances[0].balance!, decimal));
    } catch (error) {
      console.error(error);
    }
  }

  const getTokenId = async (tokenAddress: string): Promise<string> => {
    tezosAPI.defaults.baseUrl = getTezosApiUrl(network);
    const tokens = await tokensGetTokens({
      contract: {
        eq: tokenAddress,
      },
    });
    if (tokens.length != 1) {
      throw new Error(`Invalid FA2 FT! given address ${tokenAddress} has ${tokens.length} tokens.`);
    }

    return tokens[0].tokenId!;
  };

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }
  if (!account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }
  if (network != Network.TEZOS && network != Network.GHOSTNET) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the Tezos network.</Message>
      </Box>
    );
  }

  return (
    <Box title={title} id="transferFa2Ft">
      <Field label="Amount">
        <input
          className="input"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
        />
      </Field>
      <Field label="Decimal">
        <Select
          items={[
            { name: 'Decimal 18', value: '18' },
            { name: 'Decimal 6', value: '6' },
          ]}
          onSelect={(value) => setDecimal(value)}
          value={decimal}
        />
      </Field>
      <Field label="Contract Address">
        <input
          className="input"
          value={contractAddress}
          onChange={(e) => setContractAddress(e.target.value)}
        />
      </Field>
      <Field label="Receiver Address">
        <input
          className="input"
          value={receiverAddress}
          onChange={(e) => setReceiverAddress(e.target.value)}
        />
      </Field>
      <Button id="transferBtn" onClick={sendTransactionCallback}>
        Transfer {amount} FA2 token
      </Button>
      {txHash && (
        <>
          <Message type="info">Hash: {txHash}</Message>
          <Message type="info">
            <a
              href={`${getExplorerUrl(network!, txHash)}`}
              rel="noopener noreferrer"
              target="_blank">
              Explorer Link
            </a>
          </Message>
        </>
      )}
      <Button onClick={getBalance}>Get FA2 token balance</Button>
      {balance && (
        <Message type="info" className="has-text-left">
          Balance: {balance}
        </Message>
      )}
    </Box>
  );
}

export default TezosFa2Ft;
