import axios from './axios';

export async function deletePinCode(): Promise<void> {
  await axios.delete('v1/sample-dapp-operation/users/pinCode');
}

export async function getFirebaseCustomClaims(): Promise<any> {
  const { data } = await axios.get('v1/sample-dapp-operation/users/firebase');
  return data;
}

export async function deleteAccount(): Promise<void> {
  await axios.delete('v1/sample-dapp-operation/users');
}

export async function updateTerms(termsList: string[]): Promise<void> {
  await axios.put('v1/sample-dapp-operation/users/terms', { termsAgreements: termsList });
}
