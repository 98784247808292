import {
  assertArgument,
  Blockchain,
  FaceSolanaNearTransactionPayload,
  JsonRpcMethod,
  Network,
} from '@haechi-labs/face-types';
import { BigNumber } from 'ethers';
import * as nearAPI from 'near-api-js';

import { Internal } from '../internal';

export class NearProvider {
  internal: Internal;

  constructor(internal: Internal) {
    this.internal = internal;
  }

  async getPublicKeys(): Promise<nearAPI.utils.PublicKey[]> {
    const strPublicKeys = await this.internal.getAddresses(Blockchain.NEAR);
    return strPublicKeys.map((strPublicKey) => {
      return nearAPI.utils.PublicKey.fromString(
        nearAPI.utils.serialize.base_encode(Buffer.from(strPublicKey, 'hex'))
      );
    });
  }

  async getBalance(address: string, contractAddress?: string): Promise<BigNumber> {
    assertArgument(address, typeof address === 'string', 'address');
    assertArgument(contractAddress, typeof contractAddress === 'string', 'contractAddress', false);

    const res = await this.internal.sendRpc({
      method: JsonRpcMethod.near_get_balance,
      params: [address, contractAddress],
    });

    return BigNumber.from(res);
  }

  async signAndSendTransaction(transaction: nearAPI.transactions.Transaction): Promise<string> {
    // TODO: transaction 유효성 검사

    const txPayload: FaceSolanaNearTransactionPayload = {
      serializedHex: Buffer.from(
        nearAPI.utils.serialize.serialize(nearAPI.transactions.SCHEMA, transaction)
      ).toString('hex'),
    };
    const result = await this.internal.sendRpc<any>({
      method: JsonRpcMethod.near_broadcast_tx_async,
      params: [txPayload],
    });

    return result!;
  }
}
