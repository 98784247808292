import { Network } from '@haechi-labs/face-types';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { getExplorerUrl } from '../../libs/utils';
import { accountAtom, faceAtom, networkAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Field from '../common/Field';
import Message from '../common/Message';

const title = 'Aptos Contract Call';
const ca = '0xf6c858858fa7b63c2decd4753b4de18fba76c9ca2a0049f204f7ea5443d15ac7';

function AptosContractCall() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const network = useRecoilValue(networkAtom);
  const [txSignature, setTxSignature] = useState('');
  const [message, setMessage] = useState('');

  async function singleAgentTransaction() {
    try {
      const aptosProvider = face!.aptos.getProvider();
      const transaction = {
        arguments: [message],
        function: `${ca}::memo::set_message`,
        type: 'entry_function_payload',
        type_arguments: [],
      };

      const { hash } = await aptosProvider.signAndSubmitTransaction(transaction);

      setTxSignature(hash);

      console.group('[Transaction Information]');
      console.log('Transaction signature', hash);
      console.groupEnd();
    } catch (error) {
      console.error(error);
    }
  }

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  } else {
    if (Network.APTOS_TESTNET != network) {
      return (
        <Box title={title}>
          <Message type="danger">
            To test the Aptos Call, you must connect to the Aptos Testnet.
          </Message>
        </Box>
      );
    }
  }
  if (!account.balance || !account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  return (
    <Box title={title}>
      <Field label="Message">
        <input className="input" value={message} onChange={(e) => setMessage(e.target.value)} />
      </Field>
      <Button onClick={singleAgentTransaction}>Send Custom Transaction</Button>
      {txSignature && (
        <>
          <Message type="info">Hash: {txSignature}</Message>
          <Message type="info">
            <a
              href={`${getExplorerUrl(network!, txSignature)}`}
              rel="noopener noreferrer"
              target="_blank">
              Explorer Link
            </a>
          </Message>
        </>
      )}
    </Box>
  );
}

export default AptosContractCall;
