import {
  BoraPortalConnectRequest,
  BoraPortalConnectStatusResponse,
  FaceLoginResponse,
  LoginProviderType,
  Network,
} from '@haechi-labs/face-types';
import { isEthlikeBlockchain, networkToBlockchain } from '@haechi-labs/shared';
import { ethers } from 'ethers';
import forge from 'node-forge';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getAccountInfo } from '../../libs/accountInfo';
import { customTokenLogin } from '../../libs/auth';
import { faceAtom, networkAtom } from '../../store';
import { accountAtom, loginStatusAtom, sessionKeyAtom } from '../../store';
import { privateKeyAtom } from '../../store/privateKeyAtom';
import Box from '../common/Box';
import Button from '../common/Button';
import Message from '../common/Message';

const title = 'BoraPortal';

function BoraPortal() {
  const face = useRecoilValue(faceAtom);
  const [account, setAccount] = useRecoilState(accountAtom);
  const network = useRecoilValue(networkAtom);
  const prvKey = useRecoilValue(privateKeyAtom);
  const setSession = useSetRecoilState(sessionKeyAtom);
  const setIsLoggedIn = useSetRecoilState(loginStatusAtom);
  const [connectInfo, setConnectInfo] = useState<BoraPortalConnectStatusResponse>();
  const [error, setError] = useState<string | null>(null);
  const [bappUsn, setBppUsn] = useState<string | null>(null);

  useEffect(() => {
    if (account == null || account.user?.faceUserId == null) {
      return;
    }
    setBppUsn(account.user?.faceUserId);
  }, [account]);

  const getAccountInfoCallback = useCallback(async () => {
    try {
      if (!face) {
        return null;
      }

      const { address, balance, user } = await getAccountInfo(face, network!);

      console.group('[Account Information]');
      console.log('Balance:', balance);
      console.log('Address:', address);
      console.log('Current user:', user);
      console.groupEnd();

      setAccount({ address, balance, user });
    } catch (error) {
      console.error(error);
    }
  }, [face, network, setAccount]);

  function createPemFromPrivateKey(privateKey: string): string {
    return `-----BEGIN RSA PRIVATE KEY-----\n${privateKey
      .replace(/-/g, '+')
      .replace(/_/g, '/')
      .replace(/(\S{64}(?!$))/g, '$1\n')}\n-----END RSA PRIVATE KEY-----\n`;
  }

  function createSignature(rawMessage: string) {
    const messageDigest = forge.md.sha256.create();
    messageDigest.update(rawMessage, 'utf8');
    const privateKey = forge.pki.privateKeyFromPem(createPemFromPrivateKey(prvKey));

    const arrayBuffer = forge.util.binary.raw.decode(privateKey.sign(messageDigest));
    return ethers.utils.base64.encode(arrayBuffer);
  }

  async function isBoraConnected() {
    try {
      setError(null);
      const response = await face?.bora.isConnected(bappUsn!);
      console.group('[IsBoraConnected]');
      console.log('response:', response);
      console.groupEnd();

      if (response?.status) {
        setConnectInfo(response);
      }
    } catch (e) {
      setConnectInfo(undefined);
      setError(e.message);
      console.error(e);
    }
  }

  async function connectBora() {
    setError(null);
    try {
      const signatureMessage = `${bappUsn}:${account.user?.wallet?.address}`;

      const response = await face?.bora.connect({
        bappUsn,
        signature: createSignature(signatureMessage),
      } as BoraPortalConnectRequest);
      console.group('[ConnectBora]');
      console.log('rawMessage:', signatureMessage);
      console.log('signature:', createSignature(signatureMessage));
      console.log('response:', response);
      console.groupEnd();

      if (response?.status) {
        setConnectInfo(response);
      }
    } catch (e) {
      setConnectInfo(undefined);
      setError(e.message);
      console.error(e);
    }
  }

  async function boraLogin() {
    if (!bappUsn) {
      alert('bappUsn을 입력해주세요.');
      return;
    }
    try {
      const signatureMessage = `boraconnect:${bappUsn}`;
      const res = await face?.auth.boraLogin({
        bappUsn,
        signature: createSignature(signatureMessage),
      });
      setSession((res as any).session);
      setIsLoggedIn(true);
      getAccountInfoCallback();
      console.log('Login response:', res);
    } catch (e) {
      if (e.isFaceError && e.code === 4001) {
        console.log('User rejected!');
        return;
      }
      throw e;
    }
  }

  async function boraDirectSocialLogin(provider: LoginProviderType) {
    try {
      if (isEthlikeBlockchain(networkToBlockchain(face!.internal.getNetwork()))) {
        face!.getEthLikeProvider().on('connect', (connectInfo) => {
          console.log(`Connected with chainId: ${connectInfo.chainId}`);
        });
        face!.getEthLikeProvider().on('disconnect', (error) => {
          console.log(`facewallet disconnected err: ${JSON.stringify(error)}`);
        });
      }
      if (!bappUsn) {
        alert('bappUsn을 입력해주세요.');
        return;
      }
      const signatureMessage = `boraconnect:${bappUsn}`;
      const res = await face?.auth.boraDirectSocialLogin(
        {
          bappUsn,
          signature: createSignature(signatureMessage),
        },
        provider
      );
      setSession((res as any).session);
      console.log('Social Login response:', res);
      setIsLoggedIn(true);
      getAccountInfoCallback();
    } catch (e) {
      if (e.isFaceError && e.code === 4001) {
        console.log('User rejected!');
        return;
      }
      throw e;
    }
  }

  async function boraLoginWithIdToken() {
    try {
      if (!bappUsn) {
        alert('bappUsn을 입력해주세요.');
        return;
      }
      const signatureMessage = `boraconnect:${bappUsn}`;
      customTokenLogin(
        face!,
        'google.com',
        prvKey,
        (res: FaceLoginResponse | null) => {
          console.log('Facewallet Login Succeed:', res);
          setSession((res as any)?.session);
          setIsLoggedIn(true);
          getAccountInfoCallback();
        },
        {
          bappUsn,
          signature: createSignature(signatureMessage),
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  } else {
    if (Network.BORA_TESTNET != network && Network.BORA != network) {
      return (
        <Box title={title}>
          <Message type="danger">
            To test the BoraPorta API, you must connect to the Bora Testnet or BORA.
          </Message>
        </Box>
      );
    }
  }

  const isLoggedIn = !!(account.balance && account.address);

  return (
    <Box title={title}>
      {connectInfo && (
        <Message type="info" className="has-text-left">
          {connectInfo.status && <div>status: {connectInfo.status}</div>}
          {connectInfo.bappUsn && <div>bappUsn: {connectInfo.bappUsn}</div>}
          {connectInfo.boraPortalUsn && <div>boraPortalUsn: {connectInfo.boraPortalUsn}</div>}
          {connectInfo.walletAddressHash && (
            <div>walletAddressHash: {connectInfo.walletAddressHash}</div>
          )}
        </Message>
      )}
      {error && (
        <Message type="danger" className="has-text-left">
          {error}
        </Message>
      )}
      <input className="input" value={bappUsn ?? ''} onChange={(e) => setBppUsn(e.target.value)} />
      <Button onClick={() => boraLogin()} disabled={isLoggedIn}>
        [Bora] login
      </Button>
      <Button onClick={() => boraDirectSocialLogin('google.com')} disabled={isLoggedIn}>
        [Bora] direct social login (google.com)
      </Button>
      <Button onClick={() => boraLoginWithIdToken()} disabled={isLoggedIn}>
        [Bora] login with id token (google.com)
      </Button>
      <Button onClick={() => isBoraConnected()} disabled={!isLoggedIn}>
        isConnected
      </Button>
      <Button onClick={() => connectBora()} disabled={!isLoggedIn}>
        Connect
      </Button>
    </Box>
  );
}

export default BoraPortal;
