import { Blockchain } from '@haechi-labs/face-types';
import { getPlatFormCoinDecimalByBlockchain, isEthlikeBlockchain } from '@haechi-labs/shared';
import { BigNumber, ethers } from 'ethers';

export interface SentTransaction {
  hash: string;
  wait: () => Promise<{ status: boolean; internal: unknown }>;
  // 각 블록체인별 sendTransaction의 response를 그대로 반환
  internal?: unknown;
}

export interface SignedTransaction {
  hash: string;
  internal?: unknown;
}

export class Coin {
  private readonly data: BigNumber;
  private readonly decimal: number;

  constructor({ data, decimal }: { data: BigNumber; decimal: number }) {
    this.data = data;
    this.decimal = decimal;
  }

  toHexAmount(): string {
    return this.data.toHexString();
  }

  toDecimalAmountAsNumber(): number {
    return this.data.toNumber();
  }

  toDecimalAmountAsString(): string {
    return this.data.toString();
  }

  toNonDecimalAmountAsString(): string {
    return ethers.utils.formatUnits(this.data, this.decimal);
  }

  toNonDecimalAmountAsNumber(): number {
    return Number(ethers.utils.formatUnits(this.data, this.decimal));
  }
}

export function createPlatformCoin(amount: string, blockchain: Blockchain) {
  if (isEthlikeBlockchain(blockchain)) {
    return createEthCoin(amount);
  } else {
    return createNonEthCoin(amount, blockchain);
  }

  throw new Error('unknown blockchain for faucet');
}

/**
 * @param amount 0.01 같은 값
 */
function createEthCoin(amount: string): Coin {
  return new Coin({
    data: ethers.utils.parseEther(amount),
    decimal: 18,
  });
}

/**
 * @param amount 0.01 같은 값
 */
function createNonEthCoin(amount: string, blockchain: Blockchain) {
  const decimal = getPlatFormCoinDecimalByBlockchain(blockchain);
  return new Coin({
    data: ethers.utils.parseUnits(amount, decimal),
    decimal: decimal,
  });
}

export function createLargeDecimalFT(amount: string, blockchain: Blockchain) {
  if (isEthlikeBlockchain(blockchain)) {
    return new Coin({
      data: ethers.utils.parseEther(amount),
      decimal: 18,
    });
  }
  if (Blockchain.SOLANA === blockchain) {
    return new Coin({
      data: ethers.utils.parseUnits(amount, 9),
      decimal: 9,
    });
  }

  if (Blockchain.NEAR === blockchain) {
    return new Coin({
      data: ethers.utils.parseUnits(amount, 18),
      decimal: 18,
    });
  }

  if (Blockchain.TEZOS === blockchain) {
    return new Coin({
      data: ethers.utils.parseUnits(amount, 18),
      decimal: 18,
    });
  }

  throw new Error('unknown blockchain for faucet');
}

export function createSmallDecimalFT(amount: string, blockchain: Blockchain) {
  if (isEthlikeBlockchain(blockchain)) {
    return new Coin({
      data: ethers.utils.parseUnits(amount, 6),
      decimal: 6,
    });
  }
  if (Blockchain.SOLANA === blockchain) {
    return new Coin({
      data: ethers.utils.parseUnits(amount, 6),
      decimal: 6,
    });
  }
  if (Blockchain.TEZOS === blockchain) {
    return new Coin({
      data: ethers.utils.parseUnits(amount, 6),
      decimal: 6,
    });
  }

  throw new Error('unknown blockchain for faucet');
}
