import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { resolveApiKey } from '../../config/apiKey';
import axios, { getOperationApiBaseURL } from '../../libs/axios';
import {
  deleteAccount,
  deletePinCode,
  getFirebaseCustomClaims,
  updateTerms,
} from '../../libs/internalOperation';
import { accountAtom, envAtom, faceAtom, loginStatusAtom, sessionKeyAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Checkbox from '../common/Checkbox';
import Message from '../common/Message';

const title = 'Operation';
function Operation() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const setAccount = useSetRecoilState(accountAtom);
  const setIsLoggedIn = useSetRecoilState(loginStatusAtom);
  const [session, setSession] = useRecoilState(sessionKeyAtom);
  const env = useRecoilValue(envAtom);
  const [terms, setTerms] = useState<string[]>([]);

  useEffect(() => {
    if (session !== '') {
      axios.defaults.baseURL = getOperationApiBaseURL(env);
      axios.interceptors.request.clear();
      axios.interceptors.request.use(async (config) => {
        if (config.headers) {
          config.headers['X-Face-Session'] = session;
          config.headers['X-Face-Dapp-Api-Key'] = resolveApiKey(env);
          config.headers['X-Face-Dapp-Api-Hostname'] = window.location.origin;
        }
        return config;
      });
    }
  }, [env, session]);

  async function printFirebaseCustomClaims() {
    const firebaseCustomClaims = await getFirebaseCustomClaims();
    console.group('[Firebase CustomClaims Information]');
    console.log('MetaDataVersion:', firebaseCustomClaims.metaDataVersion);
    console.log('customIdentities:', firebaseCustomClaims.customIdentities);
    console.groupEnd();
  }

  async function deleteUser() {
    if (window.confirm('Are you sure you want to delete your account?')) {
      await deleteAccount();
      alert('account deleted');
      await logout();
    }
  }

  async function resetPinCode() {
    if (window.confirm('Are you sure you want to delete your pinCode?')) {
      await deletePinCode();
      alert('pinCode deleted');
    }
  }

  async function updateConsentAgreement() {
    await updateTerms(terms);
    alert('the Consent Agreements updated');
  }

  async function logout() {
    await face?.auth.logout();
    setIsLoggedIn(false);
    setSession('');
    setAccount({});
  }

  const handleTermsCheck = (checked: boolean, item: string) => {
    setTerms((prevState) => {
      if (checked) {
        return [...prevState, item];
      } else {
        const clone = [...prevState];
        clone.splice(prevState.indexOf(item), 1);
        return clone;
      }
    });
  };

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }
  if (!account.balance || !account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  const termsList = [
    'TERMS_OF_SERVICE',
    'PRIVACY_POLICY',
    'THIRD_PARTY_DISCLOSURE_OF_PERSONAL_INFO',
    'COOKIE_POLICY',
  ];

  return (
    <Box title={title}>
      <Button onClick={() => deleteUser()}>Delete account</Button>
      <Button onClick={() => resetPinCode()}>Delete PinCode</Button>
      <Button onClick={() => printFirebaseCustomClaims()}>Print FirebaseCustomClaims</Button>
      <table>
        <tbody>
          {termsList.map((terms: string) => (
            <tr key={terms}>
              <td>{terms}</td>
              <td>
                <Checkbox item={terms} onCheck={handleTermsCheck} />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={2}>
              <Button onClick={() => updateConsentAgreement()}>Update Consent Agreement</Button>
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
}

export default Operation;
