import { Face } from '@haechi-labs/face-sdk';
import { Blockchain, FaceLoginResponse, Network } from '@haechi-labs/face-types';
import { isEthlikeBlockchain, networkToBlockchain } from '@haechi-labs/shared';
import * as solanaWeb3 from '@solana/web3.js';
import { accountsGetBalance } from '@tzkt/sdk-api';
import * as tezosAPI from '@tzkt/sdk-api';
import { AptosClient, CoinClient } from 'aptos';
import { BigNumber, providers } from 'ethers';
import * as nearAPI from 'near-api-js';

import { config as nearConfig } from '../config/near';
import { getTezosApiUrl } from '../config/tezos';
import { getProvider } from './utils';

interface AccountInfo {
  address: string;
  balance: BigNumber;
  user: FaceLoginResponse;
}

export async function getAccountInfo(face: Face, network: Network): Promise<AccountInfo> {
  const blockchain = networkToBlockchain(network);
  const user = await face?.auth.getCurrentUser();

  if (blockchain === Blockchain.SOLANA) {
    return getSolanaAccountInfo(face, network, user!);
  } else if (blockchain === Blockchain.NEAR) {
    return getNearAccountInfo(face, network, user!);
  } else if (blockchain === Blockchain.APTOS) {
    return getAptosAccountInfo(face, network, user!);
  } else if (isEthlikeBlockchain(blockchain)) {
    return getEthereumLikeAccountInfo(face, network, user!);
  } else if (blockchain == Blockchain.TEZOS) {
    const account = getTezosAccountInfo(face, network, user!);
    return account;
  }

  throw new Error('unknown blockchain ' + blockchain);
}

async function getTezosAccountInfo(face: Face, network: Network, user: FaceLoginResponse) {
  const addresses = await face.tezos.wallet.getAddresses();

  tezosAPI.defaults.baseUrl = getTezosApiUrl(network);
  const balance = await accountsGetBalance(addresses[0]); // await connection.tz.getBalance(addresses[0]);

  return {
    address: addresses[0],
    balance: BigNumber.from(balance),
    user,
  };
}

async function getSolanaAccountInfo(face: Face, network: Network, user: FaceLoginResponse) {
  const solanaProvider = face.solana.getProvider();
  const publicKeys = await solanaProvider.getPublicKeys();

  const solanaNodeUrl = getProvider(network);
  const connection = new solanaWeb3.Connection(solanaNodeUrl, 'confirmed');
  const balance = await connection.getBalance(publicKeys[0]);

  return {
    address: publicKeys[0].toBase58(),
    balance: BigNumber.from(balance),
    user,
  };
}

async function getNearAccountInfo(face: Face, network: Network, user: FaceLoginResponse) {
  const nearProvider = face.near.getProvider();
  const publicKeys = await nearProvider.getPublicKeys();

  const address = Buffer.from(publicKeys[0].data).toString('hex');
  const near = await nearAPI.connect(nearConfig(network));

  const account = await near.account(address);

  return {
    address,
    balance: await account
      .getAccountBalance()
      .then((bal) => {
        return BigNumber.from(bal.total);
      })
      .catch(() => {
        return BigNumber.from('0');
      }),
    user,
  };
}

async function getAptosAccountInfo(face: Face, network: Network, user: FaceLoginResponse) {
  const aptosProvider = face.aptos.getProvider();
  const addresses = await aptosProvider.getAddresses();

  const address = addresses[0].toString();

  const aptosNodeUrl = getProvider(network);
  const aptosClient = new AptosClient(aptosNodeUrl);
  const coinClient = new CoinClient(aptosClient);

  const balance = await coinClient
    .checkBalance(address)
    .then((balance) => BigNumber.from(balance))
    .catch(() => BigNumber.from(0));

  return {
    address,
    balance,
    user,
  };
}

async function getEthereumLikeAccountInfo(face: Face, network: Network, user: FaceLoginResponse) {
  const provider = new providers.Web3Provider(face.getEthLikeProvider(), 'any');

  const signer = await provider.getSigner();
  const address = await signer.getAddress();
  const balance = await signer.getBalance();

  return {
    address,
    balance,
    user,
  };
}
